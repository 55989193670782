.blogs-landing-card-image {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    object-fit: contain;
}

.blogs-landing .card-title {
    font-size: 18px !important;
}

.posts-header1-text {
    text-align: right;
    line-height: 8px;
}

.posts-header1-strong-text {
    font-family: 'open_sansbold';
    text-align: right;
    line-height: 8px;
    font-weight: 900;
}

.post-header1-text-div {
    margin-top: 25px;
    color: #2E4570;
}

.post-header2-div {
    background-color: #116da4;
    min-height: 150px;
    background: linear-gradient(to right,  #116da4 5%,#116da4 5%,#2E4570 2%,#2E4570 100%);
}

.post-header2-title-text {
    font-family: 'open_sansbold';
    font-size: 30px;
    margin-left: 7%;
    padding-top: 20px;
    color: white
}

.post-header2-subtitle-text {
    margin-left: 7%;
    color: #39A55D !important
}

.post-footer h2 {
    font-family: 'open_sansbold';
    font-size: 20px;
    color: #116DA4;
}

.post-footer p {
    color: black
}

.post-content h1 {
    font-family: 'open_sansbold' !important;
    font-size: 30px !important;
    color: #2E4570 !important;
}

.post-content h2 {
    font-size: 20px !important;
    color: #1EB1ED !important;
}

.post-content h3 {
    font-family: 'open_sansbold' !important;
    font-size: 20px !important;
    color: #116DA4 !important;
}

.post-content {
    color: black !important;
}

.post-content img {
    width: 100% !important;
}

.post-author {
    font-family: 'open_sansbold' !important;
    margin-top: 40px;
    color: black;
}

.posts-stat-modal {
  width: 1200px !important;
}