@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansextrabold';
    src: url('../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanscondensedlight';
    src: url('../fonts/OpenSansCondensed-Light.ttf');
}

@font-face {
    font-family: 'open_sanscondensedbold';
    src: url('../fonts/OpenSansCondensed-Bold.ttf');
}