/* Stack Styles */
.section1-container,
.section2-container,
.section3-container {
  display: flex;
  flex-direction: column;
}

.section1-item,
.section2-item {
  margin: 10px 0;
}

/* Typography Styles */
.typography-body1 {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

/* Grid Styles */
.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.grid-item {
  padding: 8px;
  flex-basis: 100%;
}

/* Accordion Styles */
.accordion-root {
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
  margin: 10px 0;
}

.accordion-summary {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  align-items: center;
}

.accordion-summary-content {
  display: flex;
  align-items: center;
}

.accordion-details {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.accordion-expanded {
  margin: 10px 0;
}

.accordion-icon {
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.accordion-icon-expanded {
  transform: rotate(180deg);
}

/* Specific Section Styles */
.section1 {
  margin-top: 50px;
  margin-bottom: 50px;
}
