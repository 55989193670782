/*============================Companies Page=============================*/

.stockViewDiv {
  padding-top: 1%;
  color: #595959;
  font-family: 'open_sanssemibold';
}

.aletheia-our-people p.stockViewDiv-company {
  font-size: 13px;
  color: #777272;
  padding: 0px !important;
}
p.stockViewDiv-company {
  font-size: 12px;
  color: #9a9a9a;
  padding: 0px !important;
}

span.usd-label {
  font-weight: bold;
  color: #af0000;
}

span.tradingDate-label {
  color: #1e6ca6;
}
.aletheia-our-people .aletheia-people-content .companyContent {
  text-align: justify;
  line-height: 22px;
  display: inline-block;
  font-size: 1.4rem;
}

span.dollar-cost {
  font-size: 2.1rem;
  color: #464242;
}
span.positive-values {
  color: green;
}
span.negitave-values {
  color: red;
}
.glyphicon-arrow-up {
  font-size: 10px !important;
  color: green;
}
.glyphicon-arrow-down {
  font-size: 10px !important;
  color: red;
}
.ourppl-list {
  padding: 0;

  li {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
}

.company-page-description-section {
  margin: 1% 5%;
}

.company-card-image {
  width: 60%;
  min-height: 100px;
  max-height: 100px;
  object-fit: contain;
  vertical-align: top;
  margin-right: 30%;
}

.company-card {
  .card-avatar-section {
    background-color: white;
    width: 20% !important;
  }
}

.companies-description-section {
  padding-top: 15px;
}

.companies-image-container {
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  display: inline-block;
  position: relative;
}

@media (max-width: 768px) {
  .companies-image-container {
    width: 100px;
  }
}

@media (min-width: 768px) {
  .companies-image-container {
    width: 150px;
  }
}
