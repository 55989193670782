/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 29 July 2020 : Nicho
		* M-0002943: Homepage - Global header redesign
**************************************************************************************************************
*/
// Color Codes
//====================M001==========================
$aletheiaBlue: #2d4471;
$lightBlue: #ebf7ff;
//====================M001==========================
$placeHoldertextColor : #FFFFFF;