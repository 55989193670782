/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M002 : 24 August 2020 : Nicho
		* M-0002989: Clicking on a particular topic should show any previous blog posts
	M001 : 29 July 2020 : Nicho
		* M-0002943: Homepage - Global header redesign
**************************************************************************************************************
*/

@import './typography.scss';

body {
  background: #f5f5f5;
  color: #7e8387;
  font-family: 'open_sansregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  overflow-x: hidden;
}

main {
  margin: 80px 0 0 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

h1 {
  color: #136ca7;
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  color: #136ca7;
  font-size: 2em;
  margin: 0.67em 0;
}

h5 {
  font-size: 12px;
}

a,
a:hover,
a:focus {
  color: #136ca7;
}

a:active,
a:visited {
  color: #136ca7;
}

.btn-default {
  color: #1e6ca6;
}

.form-control {
  background-color: $lightBlue;
}

span.search-icon.glyphicon.glyphicon-glyphicon.glyphicon-search {
  position: relative;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  //====================M001==========================
  top: 8px !important;
  //transform: scaleX(-1);
  color: #ffffff;
  font-size: 22px;
  font-weight: 100;
  -webkit-text-stroke: 1px #2d4471;
  //====================M001==========================
  cursor: pointer;
}
.navbar-form .form-control {
  height: 30px;
  margin: 0px;
  width: 80%;
}
.navbar-form.navbar-left {
  margin-bottom: 0px;
  //====================M001==========================
}

// Analyst page Section CSS

.analyst-reports-box.box {
  border-radius: 10px;
}

.analyst-card-image-container {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 210px;
  height: auto;
  display: inline-block;
  position: relative;
}

.admin-analyst-card-image-container:hover {
  cursor: pointer;
}

.analyst-avatar-section {
  padding-left: 20px;
  width: 20%;
}

.analyst-reports-header {
  background: #cccccc85;
  font-size: 15px;
  padding: 10px;
  color: #206da4;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
}

.contact-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-bottom: 10px;
  p {
    line-height: 1.2;
    padding-left: 10px;
    margin: 0;
  }
}

// Product Page Our Team Section CSS

.our-team-box.box {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0 0px 10px 0px;
  .our-team-header {
    // text-align: center;
    // color: #206da4;
    // font-weight: 600;
    background: #cccccc85;
    font-size: 15px;
    padding: 10px;
    color: #206da4;
    font-weight: 700;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
  }
}

.product-page-team-box {
  display: flex;
  background-color: #f3f3f3;
  margin: 10px;
  border-radius: 6px;
  border: solid 1px #f3f3f3;
  .image-container {
    width: 80px;
    height: 75px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 0%;
    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      background-color: #2d4471;
    }
  }
  .team-info-box {
    width: 180px;
    padding-left: 10px;

    p.member-name {
      margin: 0 0 0 0;
      font-size: 14px;
      color: #1e6ca6;
      font-weight: 600;
      padding-top: 10px;
    }
    p.member-designation {
      margin: 0 0 0 0;
      font-size: 12px;
    }
  }
}

// SignUp Sign In Containers
@media (min-width: 992px) {
  .col-md-offset-2 {
    margin-left: 29.666667%;
  }
}
@media (min-width: 992px) {
  .col-md-8 {
    width: 40.666667%;
  }
}

.form-container-logo.row {
  text-align: center;
}
.box-signup .box-signup-logo {
  margin-left: 0px !important;
}

.box-signup {
  border-radius: 6px !important;
}

// Our people page header

.our-people-heading-bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  padding: 2px 0 !important;
  background-color: #f3f3f3;
  font-size: 12px;
  h2 {
    // text-transform: uppercase;
    color: #136ca7;
  }
}

// Our People Page

.contact-us-address-section {
  // cursor: pointer;
  h4 {
    margin-bottom: 5px;
  }
}

.embed-responsive-16by9 {
  padding-bottom: 34.25%;
}

.static-content {
  text-align: justify;
}

.career-banner-image {
  img.img-responsive {
    width: 100%;
  }
}

.pdf-viewer-section {
  border-radius: 5px;
  h1 {
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: 1.5em;
    margin-right: 16px;
    color: #646769;
  }
  h4 {
    color: grey;
  }
  .section-header {
    display: flex;
    justify-content: space-between;
    .btn-xs {
      padding: 4px 7px;
      font-size: 10px;
      line-height: 1.5;
      border-radius: 14px;
      position: relative;
      top: 4px;
    }
    .btn-xs:focus {
      outline: none;
      background: #337ab7;
      border-color: #2e6da4;
    }
    .btn-xs:active {
      background: #337ab7;
    }
    .back-button {
      padding-right: 5px;
      font-size: 24px;
      position: relative;
      top: 3px;
    }
    .title-section {
      display: flex;
      position: relative;
      top: 2px;
    }
    .analyst-name-section {
      position: relative;
      top: 9px;
      font-size: 14px;
    }
    .buttons-box {
      display: flex;
      margin: 8px;
      position: relative;
      bottom: 7px;
    }
    .analyst-name-section {
      position: relative;
      top: 9px;
      font-size: 14px;
    }
    .buttons-box {
      display: flex;
      margin: 8px;
      position: relative;
      bottom: 7px;
    }
  }

  button.pull-right.expand.btn.btn-xs.btn-primary {
    padding: 0;
  }
  .section-header-expanded {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 7px;
    h1 {
      margin-bottom: 4px;
      margin-top: 6px;
      font-size: 2em;
      margin-right: 16px;
      color: #646769;
    }
    .btn-xs {
      padding: 4px 7px;
      font-size: 10px;
      line-height: 1.5;
      border-radius: 14px;
      position: relative;
      bottom: 0px;
    }
    .btn-xs:focus {
      background: #337ab7;
      border-color: #2e6da4;
      outline: none;
    }
    .btn-xs:active {
      background: #337ab7;
    }
    .back-button {
      padding-right: 5px;
      font-size: 24px;
      position: relative;
      top: 0px;
    }
    .title-section {
      font-size: 8px;
      font-weight: 800;
      display: flex;
    }
    .analyst-name-section {
      position: relative;
      top: 5px;
      font-size: 14px;
    }
    .buttons-box {
      display: flex;
      margin: 0px 8px;
    }
    .analyst-name-section {
      position: relative;
      top: 5px;
      font-size: 14px;
    }
    .buttons-box {
      display: flex;
      margin: 0px 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .HighlightedSection {
    display: none;
  }
  .pdf-viewer-section h1 {
    margin: 0;
    color: #646769;
    font-size: 15px;
  }
  .pdf-viewer-section .section-header .title-section {
    display: flex;
    position: relative;
    top: 6px;
    max-width: 80%;
  }

  .pdf-viewer-section .title-section {
    display: block !important;
    padding: 2px;
  }
  .pdf-viewer-section .title-section .analyst-name-section {
    position: relative;
    bottom: 5px !important;
    top: 0 !important;
    margin-bottom: 5px;
    left: 0%;
    font-size: 12px;
  }

  .analyst-name-section {
    position: relative;
    font-size: 12px !important;
    left: 20px;
    top: 7px !important;
  }
  // HeaderTicker for mobile view/
  main {
    margin: 60px 0 0 0;
  }

  // Hide Buttons of Viewer on mobile
  button.pull-right.expand.btn.btn-xs.btn-primary {
    display: none;
  }
}

@media (min-width: 1200px) {
  .global-charts-wells {
    .col-lg-3 {
      width: 20%;
    }
  }
}

.wrapper {
  filter: blur(1px);
}

.dot {
  position: relative;
  bottom: 1px;
  margin-right: 5px;
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.forgot-password-modal.modal-body {
  @media (min-width: 992px) {
    .col-md-8 {
      width: 96.666667%;
    }
    .col-md-offset-2 {
      margin-left: 1.666667%;
    }
  }
  .hidden-xs.hidden-sm.hidden-md {
    // display: flex;
    span.pull-right {
      // position: relative;
      // left: 135px;
      // top: 4px;
      // display: none;
    }
    span.create-account {
      position: relative;
      top: 0px;
      display: flex;
      float: right;
      left: 135px;
    }
  }
  .forgot-password-button p {
    color: #136ca7;
    cursor: pointer;
    position: relative;
    bottom: 7px;
  }
}

span.modal-navigator {
  color: #337ab7;
  margin-left: 5px;
  cursor: pointer;
}
span.modal-navigator:hover {
  text-decoration: underline;
}

// body.modal-open main {
//   filter: blur(3px);
//   -webkit-filter: blur(3px);
//   -moz-filter: blur(3px);
//   -o-filter: blur(3px);
//   -ms-filter: blur(3px);
// }
.form-container-row {
  position: absolute;
  top: 12%;
  z-index: 1000;
  // width: auto;
  max-width: 45vw;
}
img.image-bg-t.img-responsive {
  // position: absolute;
}
.change-password-box.row {
  @media (min-width: 992px) {
    .col-md-8 {
      width: 50.666667%;
    }
    .col-md-offset-2 {
      margin-left: 25.666667%;
    }
    .box-signup .box-settings-logo {
      height: 81px;
      margin-left: 21%;
      margin-top: 33%;
    }
  }
}

.box-upload-image {
  border-radius: 10px;
  img.box-settings-logo {
    position: relative;
    top: 7rem;
    height: 57px;
  }
}
.box-upload-image.text-center.box {
  // height: 36rem;
  h3 {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 800;
  }
}
.box-upload-image.row {
  @media (min-width: 992px) {
    .col-md-8 {
      width: 60.666667%;
    }
    .col-md-offset-2 {
      margin-left: 19.666667%;
    }
    .box-signup .box-settings-logo {
      height: 81px;
      margin-left: 21%;
      margin-top: 33%;
    }
  }
}

.box-favourites-settings {
  border-radius: 10px;
  img.box-settings-logo {
    position: relative;
    top: 7rem;
    height: 57px;
  }
}
.box-favourites-settings.text-center.box {
  // height: 36rem;
  h3 {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 800;
  }
}
.box-favourites-settings.row {
  @media (min-width: 992px) {
    .col-md-8 {
      width: 80.666667%;
    }
    .col-md-offset-2 {
      margin-left: 10.666667%;
    }
    .box-signup .box-settings-logo {
      height: 81px;
      margin-left: 21%;
      margin-top: 33%;
    }
  }
}

span.create-account-mobile-view {
  position: relative;
  top: 10px;
}

span.login-account-mobile-view {
  position: relative;
  top: 10px;
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 12px !important;
    text-decoration: none;
    font-weight: 100 !important;
    font-family: 'open_sanssemibold';
  }
  p.sub-heading {
    font-weight: 100;
    font-size: 10.5px;
  }
  a.ticker-report-title {
    font-weight: 100 !important;
  }
  a.ticker-analyst {
    font-weight: 100 !important;
  }
  //====================M001==========================
  .filter-box-button p {
    font-weight: 100 !important;
    font-size: 12px;
    position: relative;
    top: 45%;
  }
  .product-page-team-box .team-info-box p.member-name {
    font-weight: 100 !important;
    font-size: 12px;
  }
  .our-people-section .our-people-crousel h1 {
    font-weight: 100 !important;
  }
  .container-row.row {
    margin: 0px;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
  // a.carousel-control.left {
  //   height: auto !important;
  //   border-radius: 15px;
  // }
  // a.carousel-control.right {
  //   height: auto !important;
  //   border-radius: 15px;
  // }
  .carousel-indicators li {
    border: 2px solid #206da4;
  }
  .carousel-indicators .active {
    width: 10px !important;
    height: 10px !important;
    margin: 0;
    border: 2px solid #206da4;
  }
}

.text-center.pdf-div {
  position: relative;
  z-index: 2;
}

#pdf {
  position: relative;
  z-index: 1;
}

.pdfObject-view {
  z-index: -1 !important;
}

.modal {
  position: fixed;
  z-index: 6000000;
}

.fade.logoutModal.in.modal {
  z-index: 6000;
}

@media (min-width: 1200px) {
  .container {
    width: 90%;
  }
}

.fade.container-fake.in.modal {
  z-index: 6000;
}
// .container-fake {
//   // position: absolute;
//   top: 0px;
//   width: 100%;
//   height: 100%;
//   z-index: 900000 !important;
// }
.frame-fake {
  position: absolute;
  // visibility: hidden;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  border: none;
  box-shadow: 0 5px 4px -2px #9c9c9c;
}

.frame-fake-two {
  position: absolute;
  top: 0px;
  // visibility: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  border: none;
  box-shadow: 0 5px 4px -2px #9c9c9c;
}

ul.dropdown-menu {
  z-index: 900000 !important;
  margin: 0px !important;
}

@media screen and (min-width: 768px) {
  .embed-responsive.embed-responsive-16by9 {
    height: 100%;
  }
  .text-center.pdf-div {
    // height: 100vh;
  }
  div#pdfobject {
    height: 100%;
  }

  .user-no-access-report-card {
    position: relative;
    top: 10px;
    padding-bottom: 5px;
    table {
      height: 130px;
    }
    // .main-section {
    //   margin: 5px 0px 0 0px;
    // }
    .card-description {
      font-size: 12px;
      padding: 1% 0 1% 0;
      font-family: 'open_sanssemibold';
      display: inherit;
      position: relative;
      bottom: 10px;
    }
    .research-card-image-container {
      width: 70px;
      height: 70px;
    }
    // .sub-heading {
    //   color: #1e6ca6;
    //   font-weight: 700;
    //   margin: 0px;
    //   font-size: 12px;
    //   font-family: 'open_sanssemibold';
    // }
    .date-section {
      padding-bottom: 6px;
    }
    // .subtitle-box p.subtitle-left {
    //   font-size: 12px;
    // }
    // p.subtitle-right {
    //   font-size: 12px;
    //   padding-top: 0px;
    // }
  }
}
@media screen and (max-width: 768px) {
  .text-center.pdf-div {
    // height: 80vh;
  }
  div#pdfobject {
    height: 100%;
  }

  .banner-image-preview-box {
    width: 50vw !important;
    position: relative;
    left: 16%;
    img.banner-image-preview.img-rounded {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .react-player {
    height: auto !important;
  }
  .react-player-vimeo {
    height: 82vh !important;
  }
  .custom-input {
    left: 8%;
  }
  .edit-biography {
    left: 8%;
  }
  .banner-image-preview-box {
    left: 15%;
  }
}

@media screen and (max-width: 780px) {
  .carousel-caption {
    right: 0;
    left: 1px;
    padding-bottom: 10px !important;
    bottom: 10% !important;
    h3 {
      font-size: 1.2rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
  span.glyphicon.glyphicon-chevron-right {
    display: none !important;
  }
  span.glyphicon.glyphicon-chevron-left {
    display: none !important;
  }
}

// @media screen and (max-width: 780px) {
//   .header-login-form {
//     display: flex;

//     input[type='password'] {
//       width: 2px;
//     }
//     input[type='text'] {
//       width: 70px;
//     }
//     .form-group .form-control:focus {
//       width: 1px;
//     }
//   }
//   // .header-login-form input[type='text']:focus {
//   //   width: 120px;
//   // }
// }
.related-reports-box {
  // height: 68vh;
  overflow: scroll;
}

.banner-image-preview-box {
  width: 40vw;
  position: relative;
  right: 10%;
  // text-align: center;
  // left: 10px;
}

.instruction-class {
  font-size: 10px;
  font-weight: 700;
}

.custom-input {
  width: 200px;
  height: 30px;
  display: block;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;

  font-size: 10px;
  position: relative;
  color: #fff;
  background-color: #206da4;
  border-radius: 5px;
}
.edit-biography {
  width: 200px;
  height: 30px;
  display: block;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;
  font-size: 10px;
  position: relative;
  color: #fff;
  background-color: #206da4;
  border-radius: 5px;
}

.custom-input::before {
  content: 'Select an Image';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.edit-biography::before {
  content: 'Edit Biography';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-input input {
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.edit-biography input {
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.full-screen-mode-div {
  margin-top: 1%;
}

.pdf-viewer-section.box {
  margin-bottom: 10%;
}

.delete-image {
  color: #7ba4c2;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: 9%;
  font-size: 13px;
}
.banner_holder {
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img,
  .img-responsive,
  .thumbnail a > img,
  .thumbnail > img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
.react-player-vimeo {
  height: 82vh !important;
}
@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10vw;
    color: #206da4 !important;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10vw;
    color: #206da4;
  }
  a.carousel-control.left {
    height: auto;
    border-radius: 15px;
  }
  a.carousel-control.right {
    height: auto;
    border-radius: 15px;
  }
}
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  color: #206da4;
}
//Slider Banner Border Radius

.carousel-inner {
  border-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.login-mobile-nav {
  .navbar-nav > li > a {
    padding-top: 7px;
  }
}

.save-button {
  text-align: end;
  padding-right: 2%;
}

.button-group-box {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3%;
  .btn {
    padding: 1px 18px;
  }
}

.no-slider-set {
  height: 40vh;
  position: relative;
  top: 20px;
  p {
    position: relative;
    top: 35%;
    font-size: 12px;
    font-weight: 600;
  }
}

span.slider-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
  color: #ff6639;
  cursor: pointer;
}

.carousel-caption {
  right: 0;
  left: 1px;
  padding-bottom: 30px;
  bottom: 15%;
  color: #206da4;
  h3 {
    font-size: 3rem;
  }
  p {
    font-size: 2rem;
  }
}

.video-custom-input {
  width: 200px;
  height: 30px;
  display: block;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;

  font-size: 12px;
  position: relative;
  color: #fff;
  background-color: #206da4;
  border-radius: 5px;
}

.video-custom-input::before {
  content: 'Upload Video';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-custom-input input {
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.player-wrapper-preview {
  position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  // bottom: 13px;
}
.react-player-preview {
  position: relative;
  left: 20%;
}

.box-upload-image.text-center.box h4 {
  font-size: 18px;
  padding-bottom: 10px;
  font-weight: 800;
}

.tab-content {
  .form-control {
    background-color: #ebf7ff;
    margin: 2% 0;
  }
  label.col-sm-4.control-label {
    position: relative;
    top: 1.2rem;
    text-align: left;
  }
}
.custom-input {
  width: 260px;
}
.edit-biography {
  width: 260px;
}
.tab-indexes {
  display: flex;
  font-weight: 600;
  .active-dot {
    position: relative;
    bottom: 1px;
    margin-right: 5px;
    height: 7px;
    width: 7px;
    background-color: #bebebe;
    border-radius: 50%;
    display: inline-block;
    top: 0.6rem;
    right: 6px;
  }
  .deactive-dot {
    position: relative;
    bottom: 1px;
    margin-right: 5px;
    height: 7px;
    width: 7px;
    background-color: #3e303000;
    border-radius: 50%;
    display: inline-block;
    top: 0.6rem;
    right: 6px;
  }
  p.tab-type-text {
    margin: 0;
    padding-left: 6%;
    color: #9f9e9e;
    font-size: 12px;
    padding-top: 1px;
    text-transform: capitalize;
  }
}
.warnings-div {
  position: relative;
  top: 10px;
  p.text-danger {
    font-size: 12px;
  }
}

.button-group-box-video-section {
  display: flex;
  justify-content: space-around;
  padding-top: 5%;
  .btn {
    padding: 1px 18px;
    background-color: #ffffff;
    color: #206da4;
  }
  .btn.btn-default.active {
    background-color: #206da4;
    color: white;
  }
}

.video-url-section {
  padding-bottom: 10px;
  .form-control {
    background-color: #ebf7ff;
    margin: 0% 0;
    height: 30px;
  }
  label.col-sm-3.control-label {
    position: relative;
    top: 5px;
  }
}

.save-button-row.row {
  margin-top: 5%;
  .btn-default:hover {
    color: #206da4;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

a.carousel-control.left {
  height: auto;
  border-radius: auto;
}
a.carousel-control.right {
  height: auto;
  border-radius: auto;
}

.single-slider-banner {
  position: relative;
  top: 5px;
  border-radius: 15px;
  img {
    border-radius: 15px !important;
  }

  .header-banner-video {
    video {
      object-fit: cover !important;
    }
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    bottom: 13px;
  }
  .react-player {
    position: absolute;
    top: 2%;
    left: 0;
    video {
      object-fit: fill;
    }
  }
  .react-player-vimeo {
    position: absolute;
    top: 2%;
    left: 0;
    video {
      object-fit: fill;
    }
  }
}

iframe.react-player {
  height: 82vh;
}

a.carousel-control.right {
  background-image: none !important;
}
a.carousel-control.left {
  background-image: none !important;
}

.carousel-indicators li {
  border: 2px solid #206da4;
}
.carousel-indicators .active {
  width: 15px;
  height: 15px;
  margin: 0;
  border: 2px solid #206da4;
}

input.table-header-searchBox.form-control {
  height: 30px;
  padding: 4px 10px;
  font-weight: 100;
}
.table-header-text {
  position: relative;
  top: 5px;
}

.table-search-box {
  display: flex;
}

span.search-box.glyphicon.glyphicon-glyphicon.glyphicon-remove {
  position: relative;
  right: 10%;
  top: 25%;
  font-weight: 100;
  color: #1f6ca6;
  cursor: pointer;
}
.favourites-col.col-sm-12 {
  width: 90%;
  margin-left: 4%;
}

.favourite-topic-input {
  // right: 2.5rem;
  // display: flex;

  right: 2.5rem;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #cccccc;
  // background-color: #e9f5fd;
  border-radius: 7px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: auto;
  span.slider-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
    position: relative;
    right: 4%;
    top: 20px;
    font-size: 12px;
    color: red;
    font-weight: 500;
    cursor: pointer !important;
  }
  .react-tagsinput-input {
    position: relative;
    left: 4%;
  }
  .react-tagsinput {
    // background-color: #e9f5fd;
  }
}
span.slider-edit-icon.glyphicon.glyphicon-glyphicon.glyphicon-edit {
  color: #206da4;
  font-size: 16px;
  cursor: pointer;
}
.button-group-box.favourites {
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 0px;
  }
  .btn-toolbar {
    margin-left: 10px;
  }
}
span.slider-edit-icon.glyphicon.glyphicon-glyphicon.glyphicon-edit {
  color: #206da4;
  font-size: 16px;
  cursor: pointer;
}
.button-group-box.favourites {
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 0px;
  }
}
.favourite-intsructions {
  font-size: 13px;
  padding-top: 5%;
  padding-right: 15%;
}

@media screen and (max-width: 768px) {
  span.slider-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
    position: relative;
    right: 10% !important;
    top: 10px;
    font-weight: 500;
    font-size: 10px;
    cursor: pointer !important;
  }
  span.slider-edit-icon.glyphicon.glyphicon-glyphicon.glyphicon-edit {
    color: #206da4;
    font-size: 10px;
    cursor: pointer;
  }
  .posts-box.box {
    margin-bottom: 1%;
    margin-right: 2%;
    margin-left: 2%;
    padding: 20px 9px 9px;
    text-align: left;
    span.slider-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
      position: relative;
      right: 6% !important;
      top: 2px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer !important;
    }
    span.slider-edit-icon.glyphicon.glyphicon-glyphicon.glyphicon-edit {
      color: #206da4;
      font-size: 16px;
      cursor: pointer;
      left: 16px;
      top: 1px;
    }
  }
  .favourite-topic-input {
    position: relative;
    top: 10px;
    left: 10px;
    margin-bottom: 15px;
  }
  .favourite-topic-row.row {
    margin-bottom: 3%;
    position: relative;
    left: 0% !important;
  }
  .favourite-intsructions {
    font-size: 13px;
    padding-top: 8%;
    padding-right: 0%;
  }
  .save-button {
    text-align: center;
  }
  .global-charts-well.well {
    // height: 450px !important;
  }
  .favourites-col.col-sm-10 {
    width: 100% !important;
  }
  label.favourites-col-label.col-sm-2.control-label {
    width: 60% !important;
  }
  .favourite-topic-input {
    input#formInlineName {
      width: 70%;
      margin-left: 11% !important;
    }
  }
  .react-tagsinput {
    margin-left: 0px;
    padding-top: 0px;
    width: 70%;
  }
  .topic-settings-bar span.topic-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
    right: 8% !important;
    padding: 5.1% 1% !important;
    color: red;
  }
  .rdw-editor-wrapper {
    box-sizing: content-box;
    width: 100%;
  }
  span.rdw-image-imagewrapper {
    img {
      width: 240px !important;
    }
  }
  li.favourites-menue-mobile.dropdown {
    position: relative;
    left: 10%;
  }
  .react-tagsinput-input {
    width: 70%;
  }
}

.favourites-col.col-sm-10 {
  width: 75%;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
  height: 20vh;
}
.title-posts {
  h4 {
    padding-left: 1%;
    color: #206da4;
  }
}

.global-charts-well.well {
  // height: 250px;
  min-height: 255px;
  // max-height: 250px;
}

// li.favourites-menue {
//   position: relative;
//   width: 25vw;
//   bottom: 12px;
// }
//====================M001==========================
li.favourites-menue.active {
  color: green !important;
}

.favourite-topic-row.row {
  margin-bottom: 3%;
  position: relative;
  left: 19%;
}

.rdw-emoji-wrapper {
  display: none !important;
}
.rdw-embedded-modal {
  height: 201px !important;
}
.rdw-center-aligned-block > * {
  text-align: center;
}
.public-DraftStyleDefault-ltr {
  height: 3%;
}

.DraftEditor-root {
  min-height: 20% !important;
}

.publish-post {
  button.btn.btn-default {
    padding: 2px;
    position: relative;
    top: 7px;
    font-size: 12px;
    border: none;
    margin-left: 3px;
  }
}
.publish-post-mobile {
  button.btn.btn-default {
    position: relative;
    font-size: 12px;
    border: none;
    margin-left: 3px;
  }
}

.posts-box.box {
  border-radius: 6px;
  margin-bottom: 1%;
}

.blogsDate {
  font-size: 15px;
}
.blogsDate:hover {
  cursor: pointer;
}
.TopicsMostRead:hover {
  cursor: pointer;
}
.selectedDate {
  background-color: rgb(3, 108, 166);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

//====================M002==========================
.posts-box.unpublished.box {
  height: 90px;
  overflow: hidden;
  .rdw-editor-wrapper {
    height: 50px;
  }
}

.see-more-less {
  border: none;
  color: #2d4471;
  background-color: transparent;
  font-weight: inherit !important;
  text-transform: none !important;
}

.caret.see-more {
  color: #2d4471;
  margin-top: 15px;
}

.caret.caret-up.see-less {
  border-top-width: 0;
  border-bottom: 4px solid #2d4471;
  margin-top: 15px;
}

.col-xs-12.shown {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  padding-bottom: 15px;
  float: none;
}

.col-xs-12.blur {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  padding-bottom: 15px;
  float: none;
}

.col-xs-12.blur:before {
  content: '';
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 170%;
  z-index: 2;
  //background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.738) 19%, rgba(255, 255, 255, 0.541) 34%, rgba(255, 255, 255, 0.382) 47%, rgba(255, 255, 255, 0.278) 56.5%, rgba(255, 255, 255, 0.194) 65%, rgba(255, 255, 255, 0.126) 73%, rgba(255, 255, 255, 0.075) 80.2%, rgba(255, 255, 255, 0.042) 86.1%, rgba(255, 255, 255, 0.021) 91%, rgba(255, 255, 255, 0.008) 95.2%, rgba(255, 255, 255, 0.002) 98.2%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.538) 19%,
    rgba(255, 255, 255, 0.3) 34%,
    rgba(255, 255, 255, 0.382) 47%,
    rgba(255, 255, 255, 0.278) 56.5%,
    rgba(255, 255, 255, 0.194) 65%,
    rgba(255, 255, 255, 0.126) 73%,
    rgba(255, 255, 255, 0.075) 80.2%,
    rgba(255, 255, 255, 0.042) 86.1%,
    rgba(255, 255, 255, 0.021) 91%,
    rgba(255, 255, 255, 0.008) 95.2%,
    rgba(255, 255, 255, 0.002) 98.2%,
    rgba(255, 255, 255, 0) 100%
  );
}
//====================M002==========================

.topic-settings-bar {
  display: flex;
  text-align: center;
  input.topic-input.form-control {
    width: 50%;
    margin-left: 10%;
    margin-bottom: 3%;
  }
  span.topic-delete-icon.glyphicon.glyphicon-glyphicon.glyphicon-trash {
    right: 4%;
    // top: 2.3rem;
    padding: 3.1% 1%;
    color: red;
  }
}

.favourite-topic-input {
  input#formInlineName {
    width: 70%;
    margin-left: 21%;
  }
}

.tab-name {
  display: flex;
  justify-content: space-between;
}

// .editor-class figure {
//   margin: 0;
// }
// .editor-class .rdw-image-left {
//   display: unset;
//   float: left;
// }
// .editor-class .rdw-image-right {
//   display: unset;
//   justify-content: unset;
//   float: right;
// }

.rdw-image-left {
  display: unset;
  float: left;
  margin: 1em;
}

.rdw-image-right {
  display: unset;
  justify-content: unset;
  float: right;
  margin: 1em;
}

.rdw-image-center {
  margin: 1em;
}

//allow styling  to match segment in card
.public-DraftStyleDefault-block {
  margin: unset;
}

//ensures hover on figure is always possible
.rdw-image-imagewrapper {
  position: relative;
  z-index: 101;
}

//Overwrite all pasted fonts to our 'preferred' fonts
//====================M002==========================
/*
.DraftEditor-editorContainer {
  span {
    font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
  }
}
*/
//====================M002==========================

.read-only.rdw-editor-main {
  overflow: hidden !important;
}

.DraftEditor-editorContainer {
  cursor: auto;
  // background-color: white !important;
}
// .notranslate.public-DraftEditor-content {
//   div {
//     height: 10rem;
//   }
// }
.publish-post {
  button.btn.btn-default {
    margin-top: 15%;
  }
}

.publish-post-mobile button.btn.btn-default {
  position: relative;
  font-size: 12px;
  border: none;
  margin-left: 3px;
  background-color: #e8e8e8;
}

.publish-post-mobile button.btn.btn-default.current-post {
  position: relative;
  font-size: 12px;
  border: none;
  margin-left: 3px;
  background-color: #036ca6;
  color: white;
  cursor: default;
}

.rdw-link-decorator-icon {
  display: none;
}

.box-guest-error.box {
  width: 40%;
  margin-left: 29%;
}
.box-guest-user {
  text-align: center;
}

.read-only.rdw-editor-main {
  .DraftEditor-editorContainer {
    background-color: white !important;
    box-shadow: none;
  }
}

.DraftEditor-editorContainer {
  min-height: 10rem;
  box-shadow: inset 0 1px 5px rgba(43, 43, 43, 0.12);
}

.notranslate.public-DraftEditor-content {
  min-height: 12vh;
}
@media screen and (max-width: 1024px) {
  .TopicsMostRead {
    font-size: 10px;
  }
}
.HighlightTabs:hover {
  cursor: pointer;
}
.topic-label {
  margin-top: 2%;
}
