.user-table {
  border-radius: 5px;
}

.admin-button-group.btn-group {
  button.btn.btn-sm.btn-default {
    width: 110px;
    font-size: 10px;
  }
  display: flex;
}

p.admin-table-user-status {
  text-align: center;
  padding-right: 15px;
  padding-left: 20px;
  margin: 0;
}
td.user-table {
  p {
    margin: 0;
  }
}

button.btn.btn-sm.btn-default {
  width: 100px;
}

.admin-overflow-overflow {
  overflow-x: auto;
}

span.glyphicon.glyphicon-sort {
  cursor: pointer;
}

img.banner-image-preview.img-rounded {
  width: 50%;
}

.upload-bar.row {
  position: relative;
  /* top: 15px; */
  display: flex;
  justify-content: center;
}
.auth-checkbox {
  padding: 1rem;
  margin: 2rem 0 0 !important;
}
.admin-checkbox-container {
  display: block;
  position: relative;
  width: fit-content;
  display: block;
  margin: auto;
  padding: 3px 35px;
  min-height: 14px;
  overflow: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-checkbox-container p {
  float: left;
}

/* Hide the browser's default checkbox */
.admin-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 14px;
  height: 14px;
  width: 14px;
  background-color: #ebf7ff;
  border: 2px solid rgb(180, 180, 180);
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.admin-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.admin-checkbox-container input:checked ~ .checkmark {
  background-color: #136ca7;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.admin-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.admin-checkbox-container .checkmark:after {
  left: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.upload-bar {
  .form-group {
    margin-bottom: 0 !important;
  }
}

img.team-profile-image.img-rounded {
  width: 99%;
  margin: 10% 0;
  margin-bottom: 5%;
}

.view-profile-button {
  cursor: pointer;
  color: #136ca7;
  margin-right: 10%;
}

.edit-profile-button {
  cursor: pointer;
  color: #136ca7;
  margin-left: 5%;
}

.change-photo-div {
  text-align: center;
}

.change-photo-button {
  background: #206da4;
  font-weight: 800;
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 6vh;
  padding-top: 12px;
  position: relative;
  bottom: 11px;
  width: 100%;
  cursor: pointer;
}

button.change-photo-button.btn.btn-default {
  background: #206da4;
  font-weight: 800;
  color: white;
  cursor: pointer;
}

input.custom-team-profile-input::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  bottom: 12px;
  // right: 1px;
  cursor: pointer !important;
  width: 100%;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}

form.profile-edit-form {
  .form-control {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 12px;
  }
}

.edit-profile-buttons {
  display: flex;
  padding-bottom: 2rem;
}
.edit-form-column.col-md-8.col-xs-8 {
  width: 60%;
}
button.btn-block.update.btn.btn-primary {
  height: 34px;
  position: relative;
  top: 5px;
  margin-right: 20px;
}

textarea.text-area.form-control {
  height: 8rem;
  overflow: scroll;
}

.edit-profile-error-box {
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}

.card-column.col-md-8.col-xs-6 {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .change-photo-div {
    width: 30%;
    text-align: center;
    padding-left: 25%;
  }
  .edit-form-column.col-md-8.col-xs-8 {
    width: 100%;
  }
  .upload-btn-wrapper {
    width: 40vw;
  }
  img.team-profile-image.img-rounded {
    width: 40vw;
  }
}

.instruction-class-edit-profile {
  font-size: 10px;
  font-weight: 700;
}

.EditorToolbar__root___3_Aqz {
  display: none;
}
.DraftEditor-editorContainer {
  font-size: 12px;
}
.RichTextEditor__editor___1QqIU {
  height: 18vh;
}

.DraftEditor-root {
  background-color: #ebf7fe;
}

button.our-team-disable-button.btn.btn-sm.btn-default {
  height: 3vh;
  font-size: 11px;
  padding: 0px;
}

button.fetch-report-button.btn.btn-sm.btn-default {
  font-size: 10px;
  padding: 3px;
}

button.highlight-button.btn.btn-sm.btn-default {
  width: 100%;
  font-size: 10px;
  padding: 3px;
}
.centrePagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ourFirmEditor {
  padding: 20px;
}

.admin-table-dropdown .dropdown-menu {
  margin: 0 20px 0 0;
}
