/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 05 August 2020 : Aman
		* M-0002939: Our People page should allow sort ordering of staff from the admin page.
**************************************************************************************************************
*/

//====================M001=====[====================

.tableHeader {
  background: #1e6ca6;
  font-size: 14px;
  padding: 10px;
  color: white;
  font-weight: 700;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.heading {
  color: black;
  border: 1px solid lightgray;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}
.saveBtn {
  background: #1e6ca6;
  border: 1px solid white;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.dataRow {
  border: 1px solid lightgray;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  padding: 5px;
  background-color: white;
  text-align: center;
}
//====================M001=====[====================
