.aoi-intro {
    margin-top: 50px;
}

.aoi-intro > p {
    font-size: 16px;
}

.aoi-question-head {
    text-align: center;
    color: #2d4471;
    font: 13vw "open_sansextrabold";
    .aoi-question-head-sub {
        position: absolute;
        top: 20%;
        left: 18%;
        font: 2.5vw "open_sansbold";
    }
}

.aoi-question-body {
    font-size: 16px;
    color: #2d4471;
}

.aoi-question-head-mobile {
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg) translate(-200vw,-5vw);
    text-align: center;
    color: #2d4471;
    font: 50vw "open_sansextrabold";
    .aoi-question-head-sub {
        position: absolute;
        top: 20%;
        left: 60%;
        width: 300px;
        word-break: unset;
        word-wrap: unset;
        overflow-wrap: unset;
        -webkit-hyphens: unset;
        -moz-hyphens: unset;
        -ms-hyphens: unset;
        hyphens: unset;
        font: 10vw "open_sansbold";
    }
}

@media (max-width: 420px) {
    .aoi-question-div-head-mobile{
        display: block !important;
    }
    .aoi-question-div-head-tablet{
        display: none !important;
    }
}

@media (min-width: 420px) {
    .aoi-question-div-head-mobile{
        display: none !important;
    }
    .aoi-question-div-head-tablet{
        display: block !important;
    }
}