/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
						Amendments
==============================================================================================================
    M001 : 23 July 2020 : Aman
    * M-0002945: Homepage - Tickers section redesign.
    M002 : 24 July 2020 : Aman
    * M-0002946: Homepage - Vital Information redesign.
    M003 : 29 July 2020 : Nicho
    * M-0002943: Homepage - Global header redesign
    M004 : 18 August 2020 : Aman
    * M-0002946:The width of the container seems quite narrow. Lots of text wrapping when there is plenty of width. If there is a wrap, the two sections of text should by vertically middle aligned.
    M006 : 07 September 2020 : Aman
    * M-0003013: Social media icons disappear after login
    M007 : 8 September 2020 : Aman
    * M-0002945: Homepage - Tickers section redesign
    Note: 0009001
    M008 : 8 September 2020 : Aman
    * M-0002946: Homepage - Vital Information redesign
    Note: 0009002
    M009 : 17 September 2020 : Aman
    * M-0003023: Sign off wordings become black when hover
    M010 : 8 February 2021 : Aman
    * M-0003219: Account page for user to view their details and update their password
    M011 : 26 February 2021 : Nick
    * M-0003289: Multilevel of menu bar
    M012 : 5 Aug 2022  : Nick Ng
    * M-4073: Add the No.1 in Asia badge to the header of the research portal
**************************************************************************************************************
*/

@import './sass-variables.scss';

ul.custom-nav-Items.nav.navbar-nav {
  padding-left: 10%;
}
.navbar-form .form-control {
  height: 28px;
  margin: 2px;
}

ul.custom-nav-Items.nav.navbar-nav > li > a {
  color: #fff9f9;
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
  font-weight: 600;
}

//====================M007=====[====================
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff !important;
  background-color: transparent !important;
}
//====================M007=====[====================
ul.navigation-sm.nav.navbar-nav {
  display: -webkit-box;
  padding-left: 10px;
  .open .dropdown-menu {
    position: absolute;
    float: left;
    left: -75px;
    width: auto;
    margin-top: 0;
    background-color: white;
    //====================M003==========================
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    //left: -41px;
  }
  li.dropdown.open {
    background-color: #074374;
    border-radius: 4px;
    //====================M003==========================
  }
}
//====================M003==========================
.header-divider {
  display: inline-block;
  width: 2px;
  background-color: white;
  margin: 3px 10px 0px 10px;
  height: 1em;
  float: left;
}
//====================M003==========================

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown.open {
  .dropdown-menu {
    display: none;
  }
}

.dropdown.open:hover .dropdown-menu {
  display: block;
}

.dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #1e6ca640 !important; //M011
}

ul.navigation-sm.nav.navbar-nav > li > a {
  color: #fff9f9;
  font-size: 10px;
  font-weight: 700;
  padding: 10px 10px 0px 10px;
}

.navbar-form.navbar-left {
  padding-right: 0;
  //====================M003==========================
  position: absolute;
  top: 17px;
  right: 13%;
  margin: 0;
  //====================M003==========================
  .form-control {
    height: 30px;
    //====================M003==========================
    border: none;
    background-color: $aletheiaBlue;
    color: #ffffff;
    //====================M003==========================
  }
  //====================M003==========================
  .form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
  }
  //====================M003==========================

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeHoldertextColor;
  }
  // .glyphicon for search box
  span.aletheia-msi-content.glyphicon.glyphicon-glyphicon.glyphicon-search {
    top: 2px;
    right: 20px;
    color: rgb(30, 108, 166);
  }
}

span.aletheia-mobile-nav.glyphicon.glyphicon-glyphicon.glyphicon-option-vertical {
  position: absolute;
  top: 11px;
  color: white;
  font-size: large;
  right: 0;
}

#root .nav .open > a {
  background-color: #fff0;
  border-color: #046ca6;
}
.user-profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  float: left;
  text-align: center;
  margin: 5px;
}
//====================M010=====[====================

.user-profile-icon:hover {
  cursor: pointer;
  // background: #036ca6;
  // .user-profile-name {
  //   color: white;
  // }

  -webkit-filter: brightness(70%);
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
//====================M010=====[====================

//====================M005=====[====================
.LoginSocialButtons {
  height: 15px;
  margin: 5px;
  margin-top: 13px;
  margin-right: 8px;
}

@media (min-width: 768px) {
  .LoginSocialButtons {
    margin-top: 17px;
    margin-right: 5px;
  }
}

.logo-sm {
  padding: 0%;
  margin: 7px 0px 0px 0px;
  height: 25px;
  position: relative;
  left: 10px;
  transform: scale(1.5);
}

nav.header-blue.navbar.navbar-default {
  background: $aletheiaBlue;
  padding: 5px 0px 5px 0px;
  border-radius: unset;
  margin: unset;
  //====================M003==========================
  box-shadow: 0 5px 4px -2px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  .navbar-form {
    width: 10%;
    right: 225px;
  }
  @media (max-width: 1265px) {
    .navbar-form {
      width: 20%;
      right: 225px;
    }
  }
  //====================M003==========================
}

.navbar-default {
  border: none;
  box-shadow: 0 3px 4px 0 #bababa;
}

.user-profile-name {
  font-size: 20px;
  font-weight: bold;
  color: #2d4471;
  margin: 22% 4px;
}

.profile-section {
  float: right;
  ul.custom-nav-Items.nav.navbar-nav {
    padding: 10px 0px 0px 0px;
  }
}

.profile-section-small {
  ul.custom-nav-Items.nav.navbar-nav {
    padding: 0px;
  }
}

.menu-side {
  float: right;
  padding-right: 15px;
  display: flex;
  .glyphicon {
    top: 13px;
    color: white;
  }
}

.stocksCard {
  height: 150px;
  background: #fbf9f9;
}

.stocksCardDate {
  padding: 6px 14px 0px 0px;
  font-size: 9px;
  font-weight: 700;
  color: #999;
  text-align: right;
}

h2.stocksCardTitle {
  font-size: 30px;
  color: #777;
  padding: 0px 0px 0px 15px;
  margin: 0px;
}

img.countryFlag {
  height: 23px;
  padding: 0px 13px;
}

span.companyCode {
  color: #6d6d6d;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 4px;
}

.stocksCardLinks {
  padding: 8px 0px 0px 13px;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}

.newsFeedImage {
  width: 50px;
  height: auto;
  border-radius: 4px;
  margin: 10px;
}

span.newsFeedHeading {
  font-weight: 700;
  color: #6d6d6d;
}

.newsFeedTime {
  font-size: 12px;
  margin: 5px;
  color: #777;
  width: 11%;
  text-align: right;
}

.reportsTables .table-responsive {
  width: 50%;
}

.tablekeys {
  font-size: 11px;
  font-weight: 700;
  color: #777;
}

.reportsTables .table-responsive table.table.table-bordered {
  background: #fbf9f9;
}

.tabsComponent {
  .table-responsive {
    font-size: 12px;
  }
}

.singlePanelTable {
  .table-responsive {
    font-size: 12px;
  }
  .panel-default > .panel-heading {
    color: #555;
    background-color: #d5effb;
    border-color: #ddd;
  }
}

.reportCard {
  height: auto;
  width: 100%;
  margin-top: 20px;
  background: #fffdfd;
  box-shadow: 0px 0px 4px 0px #999;
  border-radius: 3px;
  display: flex;
  color: #777;
  padding-bottom: 1%;

  .reportCardTitle {
    font-weight: 700;
    font-size: 16px;
    color: $aletheiaBlue;
    margin: 0px;
  }

  .reportCardAuthorName {
    font-weight: 700;
    font-size: 13px;
    color: #337ab7d4;
    margin-bottom: 15%;
  }
  .reportCardReadMore {
    font-size: 13px;
    font-weight: 700;
    color: #f81717d4;
    margin-bottom: 15%;
    cursor: pointer;
  }
}
//====================M003==========================
ul.custom-nav-Items.nav.navbar-nav > li > a {
  color: #fff9f9;
  font-size: 14.5px;
  font-weight: 500;
  display: -webkit-box;
  padding-top: 0px;
  padding-bottom: 0px;
}
ul.custom-nav-Items.nav.navbar-nav {
  height: 55px;
  display: flex;
  align-items: center;
}
//====================M003==========================
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #fff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #fff;
}
.login-mobile-nav {
  a:active,
  a:visited {
    color: #ffffff;
  }
}
img.reportAuthorImage {
  width: 65px;
  border-radius: 50px;
  height: 65px;
  margin-top: 30%;
}
@media screen and (max-width: 768px) {
  .navbar {
    min-height: 40px;
  }
  nav.header-blue.navbar.navbar-default.navbar-fixed-top {
    height: 45px !important;
  }
  nav.header-blue.navbar.navbar-default {
    padding: 1px;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover {
    margin: 5px 5px 5px 5px;
    padding: 5px !important;
  }
  ul.custom-nav-Items.nav.navbar-nav {
    padding: 0px;
    margin: 0px;
    display: -webkit-box;
  }
  ul.custom-nav-Items.nav.navbar-nav > li > a {
    color: #fff9f9;
    font-size: 12px;
    padding-top: 10px;
  }
  .navbar-nav {
    margin: 0px 10px;
    min-height: 40px;
  }
  .navbar-toggle {
    position: initial;
    float: right;
    left: 0;
    padding: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    background-color: transparent;
    background-image: none;
    border: 0px solid transparent;
    border-radius: 0;
  }

  .card-avatar-section {
    width: 20% !important;
  }

  .navbar-default {
    .navbar-form {
      border: none;
      box-shadow: none;
    }
    .navbar-collapse {
      border-color: #ffffff00;
    }
    .navbar-collapse.collapse.in {
      margin: 0;
    }
    .navbar-nav > .active > a:hover {
      color: #fff;
    }
  }
  .form-container-logo.row {
    text-align: center;
  }
  .box-signup .box-signup-logo {
    margin-left: 0px !important;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: white;
    border: 0;
    box-shadow: none;
    right: 0px;
  }

  .menu-side .glyphicon {
    top: 1px;
    color: white;
  }
  ul.navigation-sm.nav.navbar-nav .open .dropdown-menu {
    width: 50vw;
  }
  .dropdown:hover .dropdown-menu {
    display: none;
  }
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background-color: $aletheiaBlue;
  border-radius: 5px;
}

ul.custom-nav-Items.nav.navbar-nav {
  padding-left: 2%;
}

//====================M003==========================
.social-section-header {
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0 10px;
  //====================M003==========================
  height: 30px;
  .forgot-password-button p {
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 0;
  }
}
.header-login-signup {
  float: right;
  //====================M003==========================
  padding-top: 4px;
  .form-group {
    margin-bottom: 0px;
    display: flex;
    @media (min-width: 768px) and (max-width: 820px) {
      margin-top: 42px;
    }
    .form-control {
      display: block;
      height: 30px;
      max-width: 240px;
      margin: 2px 10px 0 5px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: $lightBlue;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $placeHoldertextColor;
    }
  }
  button.signup.btn.btn-primary {
    color: #1e6ca6;
    background-color: #ffffff;
    border-color: #2e6da4;
    margin-left: 5px;
    height: 33px;
    padding: 0px 10px 0px 10px;
    margin: 0px 4px 0px 4px;
  }
  button.btn.btn-primary {
    font-family: 'open_sansbold';
  }

  .btn-primary {
    background-color: #074374;
  }
}

// gear Icon CSS
.logoutIconHd .span .glyphicon .glyphicon-cog {
  padding: 0px !important;
  color: white !important;
}

.loggedInHd .logoutIconHd {
  color: #666666;
  width: 16px;
  height: 18px;
  float: right;
  margin-top: -6px;
  cursor: pointer;
}

.gearIcon {
  margin-top: -4px;
  font-size: 20px;
  display: block;
  color: white;
}

.logoutIconHd a {
  color: #767676;
  font-size: 14px;
  display: block;
  text-decoration: none;
  font-family: 'open_sanssemibold';
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.logoutIconHd {
  padding: 3px 0 0 0;
  font-size: 14px;
  display: block;
}

.logoutIconHd .dropdown-menu {
  right: -22px;
  margin-top: 5px;
}

li.logoutIconHd a .caret {
  display: none;
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-radius: 5px;
}

a.text-signup-style {
  font-size: 13px;
  color: #373737;
}

button.btn-block.btn.btn-default {
  display: none;
}

.temp_Admin {
  padding-top: 0.5px;
}

.ticker-section {
  position: relative;
  bottom: 13px;
  height: 27px;
  overflow: hidden;
  a.ticker-analyst {
    color: #036ca6;
    //====================M001=====[====================
    font-weight: 500;
    //====================M001=====]====================
    font-size: 12.5px;
  }
  a.ticker-report-title {
    color: #036ca6;
    //====================M001=====[====================
    font-weight: 500;
    //====================M001=====]====================
    font-size: 12.5px;
  }
  //====================M001=====[====================
  a.ticker-sector {
    color: #036ca6;
    font-size: 11px;
    border: 1px solid #036ca6;
    border-radius: 4px;
    padding: 0px 4px 0px 4px;
    text-decoration: none;
    text-transform: uppercase;
    //letter-spacing: -1px;
    //word-spacing: 1px;
    display: inline-block;
    //transform: scale(1, 1.5);
    //-webkit-transform: scale(1, 1.2);
    font-family: 'open_sanscondensedbold';
    overflow: hidden;
    margin: -4px;
    margin-right: 5px;
    margin-left: 5px;
  }
  div.ticker-report-div {
    white-space: nowrap;
    display: inline-block;
    margin-left: 15px;
  }

  //====================M001=====[====================
  a.ticker-company {
    //====================M001=====[====================
    color: #2d4471;
    //====================M001=====[====================
    margin-right: 5px;
    font-size: 12.5px;
  }
}

.ticker {
  font-size: 12.5px;
  position: relative;
  margin: 0 16px;
  //====================M001=====[====================
  top: 8px;
  //====================M001=====[====================
  height: 29px !important;
  white-space: nowrap;
  .text-color-first {
    a {
      color: #036ca6;
      font-weight: 700;
    }
  }
  .text-color-second {
    a {
      color: #036ca6;
      font-weight: 700;
    }
  }
  .text-color-third {
    a {
      color: #036ca6;
      font-weight: 700;
    }
  }
}

//====================M002=====[====================
//====================M004=====[====================
.desContainer {
  text-align: center;
}
.listContainer {
  text-align: center;
}
//====================M004=====[====================
.vital-desc {
  font-size: 17px;
  color: #036ca6;
  font-weight: bolder;
}

.vital-list {
  list-style: none;
}

.vital-list li {
  display: inline-block;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 17px;
  color: #036ca6;
  font-weight: bolder;
  border-left: 2px solid #036ca6;
}

.vital-list li:last-child {
  border-right: 2px solid #036ca6;
}
//====================M002=====[====================
//====================M004=====[====================
.desContainer {
  text-align: center;
  margin-bottom: 5px;
}
.listContainer {
  text-align: center;
}
//====================M004=====[====================
.vital-div {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 2px;
}
.vital-desc {
  font-size: 15px;
  font-family: open_sansregular, sans-serif;
  color: #036ca6;
  font-weight: bolder;
  word-spacing: 5px;
}

.vital-list {
  list-style: none;
  padding-left: 0px;
}

.vital-list li {
  display: inline-block;
  padding-left: 26px;
  padding-right: 22px;
  font-size: 14px;
  color: #036ca6;
  border-left: 3px solid #036ca6;
  text-transform: uppercase;
  font-family: open_sanslight, sans-serif;
}

.vital-list li:last-child {
  border-right: 3px solid #036ca6;
}

li.auto-suggestion-dropdown.open.dropdown {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    float: left;
    min-width: 200px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  a.dropdown-toggle {
    display: none;
  }
}

.dropdown,
.dropup {
  position: relative;
  list-style: none;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    //====================M003==========================
    height: 90px !important;
    margin-top: -16px;
    padding: 0px !important;
  }
  nav.header-blue.navbar.navbar-default.navbar-fixed-top {
    height: 90px;
  }
}
//====================M003==========================
@media (min-width: 768px) and (max-width: 1080px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    //====================M003==========================
    margin-top: -16px;
    padding-left: 20px !important;
    margin-right: 10px;
  }
}
//====================M003==========================
nav.header-blue.navbar.navbar-default.navbar-fixed-top {
  //====================M003==========================
  height: 70px;
  border-bottom: 1px solid white;
}
// Header responsiveness set
@media (max-width: 1159px) and (min-width: 984px) {
  .navbar {
    width: auto;
  }
  // div#root {
  //   width: 1155px;
  // }
}
//Tablet Media
@media (max-width: 992px) and (min-width: 768px) {
  nav.header-blue.navbar.navbar-default.navbar-fixed-top {
    //====================M003==========================
    height: 70px !important;
  }
  .col-sm-offset-2 {
    margin-left: 0%;
  }
  .profile-section {
    float: right;
    position: relative;
    right: 20px;
    top: 7px;
  }

  .navbar {
    width: auto;
    .container {
      width: auto;
      margin-right: 5%;
      margin-left: 5%;
    }
  }
  // .navbar-brand {
  //   float: left;
  //   height: 35px;
  //   position: relative;
  //   top: 6px;
  // }
  ul.custom-nav-Items.nav.navbar-nav {
    padding-left: 1%;
    padding-top: 1%;
  }
  .header-login-signup {
    // width: 42%;
    top: 2%;
    position: relative;
    left: 5px;
  }
  .navbar-form.navbar-left {
    padding-top: 0px;
    padding-left: 0px;
  }
  .navbar-form.navbar-left .form-control {
    height: 30px;
    margin: 0px;
    // width: 90%;
  }
  .header-login-signup .form-group .form-control {
    width: 80px;
    // height: 30px;
  }

  .header-login-form .form-group .form-control:focus {
    width: 100px;
  }
  form.header-login-form
    span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    right: 24px;
    top: 12px;
    font-size: 11px;
  }
  form.header-login-form span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    right: 24px;
    top: 12px;
    font-size: 11px;
  }
  .delete-image {
    top: 10px !important;
    right: 3% !important;
    font-size: 13px;
  }

  .ticker {
    font-size: 12.5px;
    position: relative;
    margin: 0 20px;
    height: 29px !important;
    white-space: nowrap;
  }
}

li.login-mobile-nav {
  // position: relative;
  // right: 15px;
  a {
    color: #fff;
    font-weight: 600;
  }
}

@media (min-width: 992px) {
  .navbar-form {
    width: 20%;
  }
}
//====================M003==========================
nav.header-blue.navbar.navbar-default.navbar-fixed-top {
  .container {
    padding: 0;
    height: 100%;
  }
}
//====================M003==========================

.dropdown-menu > li.loggedInAs {
  hr {
    margin-top: 2px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #72d5f9;
  }
}

.dropdown-menu > li.loggedInAs > a:hover {
  text-decoration: none;
  background-color: white;
  cursor: unset;
}
.logged-as-section {
  text-align: center;
  font-size: 12px;
}
.user-name {
  text-align: center;
  p {
    text-transform: uppercase;
    font-size: 15px;
    margin: 0;
  }
}
.navbar-nav > li > .dropdown-menu > li > a {
  text-transform: none;
}

// @media (max-width: 902px) and (min-width: 772px) {
//   nav.header-blue.navbar.navbar-default .navbar-form {
//     display: none;
//   }
//   .header-login-signup .form-group .form-control {
//     max-width: 143px;
//     height: 30px;
//   }
// }

.navbar-nav > li > .dropdown-menu {
  min-width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 778px) {
  .navbar {
    min-height: 50px;
  }
}

// .glyphicon-eye-close {
//   color: #3789c5;
// }
// .glyphicon-eye-open {
//   color: #3789c5;
// }
form.header-login-form {
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    right: 9%;
    top: 10px;
  }
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    right: 9%;
    top: 10px;
  }
}
.forgot-password-modal.modal-body {
  span.showHide-icon.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    top: 24px;
    right: 10px;
  }
  span.showHide-icon.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    top: 24px;
    right: 10px;
  }
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    right: 2%;
    top: -40px;
  }
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    right: 2%;
    top: -40px;
  }
}
.box-signup.box {
  span.showHide-icon.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    top: 24px;
    right: 10px;
  }
  span.showHide-icon.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    top: 24px;
    right: 10px;
  }
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-close {
    float: right;
    right: 2%;
    top: -40px;
  }
  span.showHide-icon-login.glyphicon.glyphicon-glyphicon.glyphicon-eye-open {
    float: right;
    right: 2%;
    top: -40px;
  }
}

.login-account-ipad-view {
  text-align: center;
  position: relative;
  top: 10px;
  right: 25%;
}

.signup-account-ipad-view {
  text-align: center;
  position: relative;
  top: 10px;
  left: 25%;
}

// Header Breaks in dimension from 1198 to 990
@media only screen and (min-width: 985px) and (max-width: 1035px) {
  nav.header-blue.navbar.navbar-default .navbar-form {
    width: 16%;
  }
}

// .ticker-section {
//   background: #e8e8e8ed;
//   position: relative;
//   bottom: 0;
//   top: 15%;
//   height: 40px;
// }

ul.custom-gear-menu.nav.navbar-nav {
  display: flex;
}

@media (max-width: 1200px) {
  div#searchBox {
    display: none;
  }
}

.header-login-signup .form-group {
  margin-top: 0 !important;
}

@media (max-width: 992px) and (min-width: 768px) {
  .header-login-signup {
    position: relative;
    right: 10%;
  }
}



// @media (max-width: 820px) and (min-width: 700px) {
//   .header-login-signup {
//     position: relative;
//     left: -2%;
//     bottom: 4rem;
//   }
//   //====================M003==========================
//   .navbar-header {
//     position: relative;
//     margin-left: 0 !important;
//     margin-right: 0 !important;
//   }
//   //====================M003==========================
//   ul.custom-nav-Items.nav.navbar-nav {
//     position: relative;
//     right: 4%;
//   }
//   li.favourites-menue {
//     //====================M003==========================
//     width: auto;
//     //====================M003==========================
//   }
// }

// @media only screen and (device-width: 768px) and (device-height: 1024px) {
//   /* css rules for ipad portrait and landscape */
//   .header-login-signup {
//     position: relative;
//     left: 5%;
//     bottom: 4rem;
//   }
//   .navbar-header {
//     position: relative;
//     right: 4%;
//   }
//   ul.custom-nav-Items.nav.navbar-nav {
//     position: relative;
//     right: 4%;
//   }
//   li.favourites-menue {
//     width: auto;
//   }

//   li.favourites-menue.dropdown.open {
//     ul.dropdown-menu {
//       //====================M003==========================
//       padding-bottom: 5%;
//       //====================M003==========================
//     }
//   }
// }

@media (max-width: 992px) and (min-width: 768px) {
  .user-profile-icon {
    height: 30px;
    width: 30px;
    margin-top: 10px;
  }
  .user-profile-name {
    font-size: 15px;
  }
  .LoginSocialButtons {
    margin: 0px;
    margin-top: 17px;
    margin-right: 5px;
  }
  .navbar-default .navbar-nav > li > a {
    font-size: 12px;
  }

  ul.custom-nav-Items.nav.navbar-nav > li > a {
    font-size: 12px;
    font-weight: bold;
    padding: 0px 5px;
  }
}

// @media only screen and (device-width: 768px) and (device-height: 1024px) {
//   .navbar .container {
//     width: auto;
//     margin-right: 3%;
//     margin-left: 3%;
//   }
// }

//Aletheia Mobile Fixes
@media (max-width: 440px) {
  .user-profile-icon {
    height: 35px;
    width: 35px;
  }
  .user-profile-name {
    font-size: 17px;
  }
  ul.navigation-sm.nav.navbar-nav {
    padding-left: 20px;
  }
  //====================M005=====[====================
  .ticker-section {
    height: 27px;
  }
  //====================M005=====[====================
  //Vital Info Part
  //====================M006=====[====================
  .vital-list li {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 13px;
  }
  .vital-desc {
    font-size: 12px;
  }
  //====================M006=====[====================
}

.nav > li > a {
  position: relative;
  display: block;
  /* padding: 10px 15px; */
  padding-right: 10px;
}

//====================M011=====[====================

.multilevel_menu_0 {
  background-color: #fff;
  background-clip: padding-box;
}

.multilevel_menu_0-menu {
  position: absolute;
  top: -5px;
  left: 100%;
  z-index: 1000;
  display: block;
  min-width: 250px;
  padding: 5px 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
}

.multilevel_menu_0-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.multilevel_menu_0:hover .multilevel_menu_0-menu {
  display: block;
}

.multilevel_menu_0.open {
  .multilevel_menu_0-menu {
    display: none;
  }
}

.multilevel_menu_0.open:hover .multilevel_menu_0-menu {
  display: block;
}

.multilevel_menu_0:hover .multilevel_menu_0-menu {
  display: none;
}

.multilevel_menu_0-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #1e6ca640 !important;
}

.multilevel_menu_0-toggle .caret {
  transform: rotate(-90deg);
  float: right;
  position: absolute;
  left: 90%;
  top: 45%;
}

//====================M011=====[====================

.multilevel_menu_1 {
  background-color: #fff;
  background-clip: padding-box;
}

.multilevel_menu_1-menu {
  position: absolute;
  top: -5px;
  left: 100%;
  z-index: 1000;
  display: block;
  min-width: 250px;
  padding: 5px 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
}

.multilevel_menu_1-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.multilevel_menu_1:hover .multilevel_menu_1-menu {
  display: block;
}

.multilevel_menu_1.open {
  .multilevel_menu_1-menu {
    display: none;
  }
}

.multilevel_menu_1.open:hover .multilevel_menu_1-menu {
  display: block;
}

.multilevel_menu_1:hover .multilevel_menu_1-menu {
  display: none;
}

.multilevel_menu_1-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #1e6ca640 !important;
}

.multilevel_menu_1-toggle .caret {
  transform: rotate(-90deg);
  float: right;
  position: absolute;
  left: 90%;
  top: 45%;
}
//====================M011=====]====================

//====================M012=====[====================
.header-2022-badge {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
  margin-left: 40px;
}
//====================M012=====]====================