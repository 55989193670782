/*
**************************************************************************************************************
                        R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
  Author :	Nick Ng
  Date	:	23 January 2023
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
  M007 : 20 January 2023 : Zeeshan
    * M-0004062: Smoothen homepage banner carousel spinning
  M006 : 25 November 2020 : Nick
    * M-0003160: Our Research background colour should be a shade darker from branding book
  M005 : 8 September 2020 : Aman
    * M-0002944: Homepage - Banner section redesign 
    Note:0009000    
  M004 : 14 August 2020 : Nicho
	  * M-0002968: Homepage - Our Research section redesign
  M003 : 14 August 2020 : Nick
		* M-0002944: Homepage - Banner section redesign
	M002 : 5 August 2020 : Nicho
		* M-0002970: Homepage - Vision section redesign
	M001 : 4 August 2020 : Nicho
    * M-0002969: Homepage - Our People section redesign 
**************************************************************************************************************
*/

// HomePage - Slider CSS
.homepage-slider {
  // background-image: url('../images/hero-banner.jpg');
  background-color: white;
  height: auto;
  position: relative;
  bottom: 15px;
  background-repeat: round;
  width: auto;
  bottom: 14px;

  button.btn.btn-lg.btn-primary {
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 4px;
  }

  h1 {
    padding-top: 5px;
    font-weight: 700;
    font-size: 25px;
  }
}

.header-banner-video {
  video {
    object-fit: cover !important;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
  bottom: 13px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  video {
    object-fit: fill;
  }
}

.react-player-vimeo {
  position: absolute;
  top: 0;
  left: 0;

  video {
    object-fit: fill;
  }
}

.text-center.slider-text {
  color: #206da4;

  img.banner-image {
    width: 1585px;
  }
}

// HomePage - Our People CSS
//====================M001==========================
.our-people-section {
  .swiper-slide.our-people-crousel {
    // height: 240px;
    width: 20%;
  }

  padding: 70px 5px 50px 5px;

  .section-title {
    font-weight: 700;
    text-align: center;
    margin: -5px 0 0 0;
  }

  .title-bar {
    background-color: #036ca6;
    content: '';
    display: inline-block;
    height: 2.5px;
    position: relative;
    vertical-align: middle;
    width: 95%;
    left: 0.6em;
  }

  @media (min-width: 1200px) {
    .col-lg-2 {
      width: 17.3%;
    }
  }

  .center {
    height: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .center.icon-left {
    width: 40px !important;
  }

  .center.icon-right {
    width: 15px !important;
  }

  .our-people-crousel {
    text-align: center;

    p {
      font-size: 1.1rem;
      text-align: center;
      color: grey;
      text-transform: uppercase;
    }

    h1 {
      color: #036ca6;
      font-size: 1.5rem;
      text-align: center;
      margin: 15px 0 5px 0px;
      font-weight: 750;
    }

    img.img-responsive {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    border-radius: 8px;
  }

  //====================M001==========================
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    display: none;
  }

  .swiper-container {
    width: 95%;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
  }

  .our-people-bar {
    margin: 0 auto;
    width: 80%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  span.slider-right.glyphicon.glyphicon-glyphicon.glyphicon-menu-right {
    position: relative;
    font-size: 13px;
    cursor: pointer;
    color: white;
    background-color: #036ca6;
    border-radius: 50%;
    padding: 4px;
    border: 0.2px solid #f5f5f5;
  }

  span.slider-left.glyphicon.glyphicon-glyphicon.glyphicon-menu-left {
    position: relative;
    font-size: 13px;
    cursor: pointer;
    color: white;
    background-color: #036ca6;
    border-radius: 50%;
    padding: 4px;
    border: 0.2px solid #f5f5f5;
  }
}

img.team-member-image.img-circle {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 3px solid #2d4471;
  background-color: #2d4471;
}

img.team-member-report-image.img-circle {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px solid #2d4471;
  background-color: #2d4471;
}

//====================M001==========================
.right-crousel-button {
  height: 30px;
  background-color: #206da4;
  width: 30px;
  border-radius: 50px;
  position: relative;
  bottom: 30px;
}

.chevron-glygh-crousel {
  color: white;
  top: 5.5px;
  font-size: 17px;
  left: 6.5px;
}

.sc-EHOje.aoTUt.sc-ifAKCX.euKzJn {
  width: 30px;
}

.sc-bZQynM.dHQcjo.sc-ifAKCX.euKzJn {
  width: 30px;
}

// HomePage - Our Research CSS
//====================M004==========================
.research-container {
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #036ca6; //M006
  height: 490px;
}

.our-research-section {
  width: 90%;
  padding: 70px 5px 20px 5px;
  margin-left: auto;
  margin-right: auto;

  .section-title {
    color: white;
    font-family: open_sansbold;
    text-align: right;
    margin: -5px 0 0 0;

    .text-center.row {
      -webkit-box-sizing: initial;
      -moz-box-sizing: initial;
    }
  }

  .title-bar {
    background-color: white;
    content: '';
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 95%;
    left: 0.6em;
  }

  .center {
    height: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .center.icon-left {
    width: 40px !important;
  }

  .center.icon-right {
    width: 15px !important;
  }

  .our-research-bar {
    margin: 0 auto;
    width: 80%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  span.slider-right.glyphicon.glyphicon-glyphicon.glyphicon-menu-right {
    position: relative;
    font-size: 13px;
    cursor: pointer;
    color: #036ca6;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    border: 0.2px solid #199bd7;
  }

  span.slider-left.glyphicon.glyphicon-glyphicon.glyphicon-menu-left {
    position: relative;
    font-size: 13px;
    cursor: pointer;
    color: #036ca6;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    border: 0.2px solid #199bd7;
  }

  .swiper-container {
    width: 97%;
    height: 310px;
    padding: 10px;
    padding-top: 28px;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
  }

  .swiper-scrollbar {
    height: 2px !important;
    bottom: 7px !important;
    background-color: white;
  }

  .swiper-scrollbar-drag {
    height: 12px;
    top: -5px;
    background-color: #2d4471;
    border: 1.5px solid white;
    -webkit-transform-origin: 0 0 0;
    -moz-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0 !important;
  }
}

/*
.research-tile.dark {
  border: 2px solid #8589a6;
}
.research-tile.card-index-1 {
  border: 2px solid #8589a6;
}
.research-tile.card-index-3 {
  border: 2px solid #8589a6;
}
.research-tile.card-index-5 {
  border: 2px solid #8589a6;
}
.research-tile.card-index-7 {
  border: 2px solid #8589a6;
}
*/

.research-tile {
  text-align: center;
  height: 240px !important;
  background-color: white;
  border: 3px solid #284571;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 0 10px;

  .img-rounded {
    margin-top: 15px;
    width: 100px;
  }

  h1.research-tile-title {
    margin: 2px 5px;
    color: #036ca6;
    color: white;
    font-size: 12.5px;
    text-decoration: none;
    font-family: open_sanssemibold;
    padding: 10px 0;
  }

  p.research-subtitle {
    font-size: 12px;
    color: #a8a8a8;
    color: white;
    margin: 0 5px;
    padding-bottom: 0px;
    position: absolute;
    bottom: 53px;
    left: 0px;
    width: 95%;

    a {
      color: white;
    }
  }

  p.research-tile-sector {
    font-size: 12.5px;
    color: #fff;
    border: 2px solid #204471;
    border-radius: 15px;
    text-transform: uppercase;
    margin-left: 11px;
    margin-right: 11px;
    margin-top: 65px;
    padding: 4px;
    font-family: 'open_sanscondensedbold';
    background: #036ca6;
    //letter-spacing: 1px;
  }

  p.research-tile-analyst {
    margin: 0;
    font-weight: 700;
    font-size: 11.5px;
    font-family: 'open_sanssemibold';
    position: absolute;
    bottom: 33px;
    left: 0px;
    width: 100%;
    //color: white; For Later Implementation
  }

  p.research-tile-date {
    font-size: 10.5px;
    position: absolute;
    bottom: 8px;
    left: 0px;
    width: 100%;
    color: hsla(0, 0%, 100%, 0.8);
  }
}

//====================M004==========================
//====================M002==========================
// HomePage - Icons above Vision Mission Values
.vmv-container {
  text-align: center;
}

.vmv-container.row {
  margin-right: 0px;
  margin-left: 0px;
  background-color: white;
  height: 458px;
  padding-top: 40px;

  @media (min-width: 768px) and (max-width: 1451px) {
    height: 420px;
    padding-top: 30px;
  }

  @media (width: 768px) {
    height: 350px;
    padding-top: 20px;
  }

  @media (max-width: 767px) {
    height: 770px;
  }
}

.vmv-text.container {
  @media (min-width: 1200px) {
    width: 85%;
  }
}

.vmv-icons-section {
  //====================M002==========================
  height: 120px;
  //====================M002==========================
  padding: 30px 4% 0 4%;
  z-index: 1;
  position: relative;

  .vmv-tooltip {
    position: relative;
    display: inline-block;
  }

  .vmv-tooltip .vmv-tooltip-text {
    visibility: hidden;
    width: 120px;
    //border-style: solid;
    //border-color: #2d4471;
    //border-width: 1px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 88%;
    left: 50%;
    margin-top: 5px;
    margin-left: -63px;
    color: #036ca6;
    //====================M002==========================
    //background-color: white;
    //====================M002==========================
  }

  .vmv-tooltip .vmv-tooltip-text::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: 5px;
    margin-bottom: 4px;
    border-width: 5px;
    border-style: solid;
    //border-color: transparent transparenvit #2d4471 transparent;
    border-color: transparent transparent #036ca6 transparent;
  }

  .vmv-tooltip:hover .vmv-tooltip-text {
    visibility: visible;
  }

  /*
  img:hover {
    border-radius: 50%;
    box-shadow: 0px 0px 5px #b3b3b3;
    -moz-box-shadow: 0px 0px 5px #b3b3b3;
    -webkit-box-shadow: 0px 0px 5px #b3b3b3;
  }
  */
  @media (min-width: 1629px) and (max-width: 1762px) {
    padding: 35px 4% 0 4%;

    img {
      margin: 10px !important;
    }
  }

  @media (min-width: 1362px) and (max-width: 1629px) {
    padding: 40px 4% 0 4%;

    img {
      height: 80px !important;
      margin: 10px !important;
    }
  }

  @media (min-width: 1096px) and (max-width: 1362px) {
    padding: 40px 4% 0 4%;

    img {
      height: 70px !important;
      margin: 5px !important;
    }
  }

  @media (min-width: 1038px) and (max-width: 1096px) {
    img {
      height: 70px !important;
      margin: 3px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1037px) {
    img {
      height: 65px !important;
      margin: 1px !important;
    }

    .vmv-tooltip:hover .vmv-tooltip-text {
      visibility: hidden;
    }

    height: 150px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

// HomePage - Background text
.vision-bg {
  position: absolute;
  top: -126px;
  right: 0vw;
  color: #f7f7f7;
  font: 15em 'open_sansextrabold';

  @media (min-width: 1451px) and (max-width: 1796px) {
    top: -115px;
    font-size: 13em;
  }

  @media (min-width: 1145px) and (max-width: 1451px) {
    top: -95px;
    font-size: 11em;
  }

  @media (min-width: 992px) and (max-width: 1145px) {
    top: -85px;
    font-size: 9em;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    top: -80px;
    font-size: 8em;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.mission-bg {
  position: absolute;
  top: 10px;
  left: 0vw;
  color: #f7f7f7;
  font: 15em 'open_sansextrabold';

  @media (min-width: 1451px) and (max-width: 1796px) {
    top: 30px;
    font-size: 13em;
  }

  @media (min-width: 1145px) and (max-width: 1451px) {
    top: 50px;
    font-size: 11em;
  }

  @media (min-width: 992px) and (max-width: 1145px) {
    top: 75px;
    font-size: 9em;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    top: 90px;
    font-size: 8em;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.values-bg {
  position: absolute;
  top: 179px;
  right: 0vw;
  bottom: 0;
  color: #f7f7f7;
  font: 15em 'open_sansextrabold';

  @media (min-width: 1451px) and (max-width: 1796px) {
    top: 209px;
    font-size: 13em;
  }

  @media (min-width: 1145px) and (max-width: 1451px) {
    top: 214px;
    font-size: 11em;
  }

  @media (min-width: 992px) and (max-width: 1145px) {
    top: 245px;
    font-size: 9em;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    top: 260px;
    font-size: 8em;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

// HomePage - Our Mission CSS

.our-mission-section {
  height: 220px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  .mission-block {
    padding-top: 70px;
    color: #036ca6;
    flex: 1 0 0;
    -webkit-flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 220px;
    border-radius: 10px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1362px) {
      padding-top: 45px;
    }

    p {
      font-family: open_sansregular;
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 auto;
      line-height: 20px;
      padding-bottom: 15px;
      text-align: center;

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 1.3rem;
      }
    }

    .section-title {
      overflow: hidden;
      width: 100%;
      font-size: 1.7em;
      font-weight: 650;
      text-align: center;
      margin: 1% 0 10% 0;

      .text-center.row {
        -webkit-box-sizing: initial;
        -moz-box-sizing: initial;
      }
    }

    .section-title:before,
    .section-title:after {
      background-color: #036ca6;
      content: '';
      display: inline-block;
      height: 2.5px;
      position: relative;
      vertical-align: middle;
      width: 100%;
    }

    .section-title:before {
      right: 1em;
      margin-left: -77.7%;
    }

    .section-title:after {
      left: 1em;
      margin-right: -77.7%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .section-title:before {
        right: 0.3em;
        margin-left: -82%;
      }

      .section-title:after {
        left: 0.3em;
        margin-right: -82%;
      }
    }
  }
}

// HomePage - Our vision CSS

.our-vision-section {
  height: 220px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (width: 768px) {
    height: 250px;
  }

  .vision-block {
    padding-top: 70px;
    color: #036ca6;
    flex: 1 0 0;
    -webkit-flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 220px;
    border-radius: 10px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1362px) {
      padding-top: 45px;
    }

    p {
      font-family: open_sansregular;
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 auto;
      line-height: 20px;
      padding-bottom: 15px;
      text-align: center;

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 1.3rem;
      }
    }

    .section-title {
      overflow: hidden;
      width: 100%;
      font-size: 1.7em;
      font-weight: 650;
      text-align: center;
      margin: 1% 0 10% 0;

      .text-center.row {
        -webkit-box-sizing: initial;
        -moz-box-sizing: initial;
      }
    }

    .section-title:before,
    .section-title:after {
      background-color: #036ca6;
      content: '';
      display: inline-block;
      height: 2.5px;
      position: relative;
      vertical-align: middle;
      width: 100%;
    }

    .section-title:before {
      right: 1em;
      margin-left: -75.8%;
    }

    .section-title:after {
      left: 1em;
      margin-right: -75.8%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .section-title:before {
        right: 0.3em;
        margin-left: -82%;
      }

      .section-title:after {
        left: 0.3em;
        margin-right: -82%;
      }
    }
  }
}

// HomePage - Our Values CSS

.our-values-section {
  height: 220px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  .values-block {
    padding-top: 70px;
    flex: 1 0 0;
    -webkit-flex: 1;
    min-height: 220px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1362px) {
      padding-top: 45px;
    }

    .section-title {
      overflow: hidden;
      width: 100%;
      font-size: 1.7em;
      font-weight: 650;
      text-align: center;
      margin: 1% 0 10% 0;

      .text-center.row {
        -webkit-box-sizing: initial;
        -moz-box-sizing: initial;
      }
    }

    .section-title:before,
    .section-title:after {
      background-color: #036ca6;
      content: '';
      display: inline-block;
      height: 2.5px;
      position: relative;
      vertical-align: middle;
      width: 100%;
    }

    .section-title:before {
      right: 1em;
      margin-left: -75.8%;
    }

    .section-title:after {
      left: 1em;
      margin-right: -75.8%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .section-title:before {
        right: 0.3em;
        margin-left: -82%;
      }

      .section-title:after {
        left: 0.3em;
        margin-right: -82%;
      }
    }

    .values-section {
      position: relative;

      h1 {
        color: #036ca6;
        font-size: 1.2em;
        text-align: center;
        position: relative;

        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 1.1rem;
        }
      }

      .values-dot {
        position: relative;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #036ca6;
        display: inline-block;
        margin: 3px 4%;
      }
    }
  }
}

//====================M002==========================

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
  vertical-align: middle;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 2px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  /* vertical-align: middle; */
  background-color: #777;
  border-radius: 10px;
  margin-left: 9px;
}

.modal-dialog {
  position: relative;
  top: 60px;
}

.post-modal .modal-dialog {
  width: 70%;
}

//====================M003==========================
.banner-holder {
  width: 100%;
  min-height: 20%;
}

.SwiperBannerImages {
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 1170px) {
  .banner-holder .swiper-wrapper {
    margin-top: 0%;
    margin-bottom: 5%;
  }
}

@media (min-width: 1170px) {
  .banner-holder .swiper-slide-prev {
    transform: translateX(-25%) scale(1.8);
  }

  .banner-holder .swiper-slide-next {
    transform: translateX(25%) scale(1.8);
  }

  .banner-holder .swiper-wrapper {
    margin-top: 8%;
    margin-bottom: 9%;
  }

  .banner-holder .swiper-slide {
    filter: brightness(80%);
    -webkit-filter: brightness(80%);
    -moz-filter: brightness(80%);
    -o-filter: brightness(80%);
    -ms-filter: brightness(80%);
    box-shadow: 0px 0px 2px 2px #1a27425c;
    opacity: 0.4;
  }

  .banner-holder .swiper-slide-active {
    transform: scale(2);
    z-index: 1;
    filter: blur(0px) !important;
    -webkit-filter: blur(0px) !important;
    border-radius: 8px;
    opacity: 1;
    border: 1px solid white;
  }

  .swiper-button-prev {
    left: 14% !important;
  }

  .swiper-button-next {
    right: 14% !important;
  }

  .swiper-button-next.swiper-button-white {
    background-image: url(../images/navigate_next-aletheia-dark-blue.svg) !important;
    background-size: 125px;
    height: 70px;
    width: 40px;
  }

  .swiper-button-prev.swiper-button-white {
    background-image: url(../images/navigate_previous-aletheia-dark-blue.svg) !important;
    background-size: 125px;
    height: 70px;
    width: 40px;
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 2px !important;
    display: inline-block;
    background: #2e4470 !important;
    opacity: 1 !important;
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet-active {
    height: 3px !important;
    background: #2e4470;
  }
}

//====================M003==========================

@media (max-width: 440px) {
  //Banner Part
  .swiper-button-prev.swiper-button-white {
    background-image: none !important;
  }

  .swiper-button-next.swiper-button-white {
    background-image: none !important;
  }

  //====================M005=====[====================
  .swiper-pagination-bullet {
    position: relative;
    top: 6px;
  }

  //====================M005=====[====================
  //Our Reasearch Part
  .section-title-research {
    font-size: 23px;
    position: relative;
    left: 45%;
  }

  .title-bar {
    display: none !important;
  }

  .our-research-section .center.icon-right {
    display: none;
  }

  .our-research-section .center.icon-left {
    display: none;
  }

  //Our People
  .section-title-people {
    font-size: 24px;
    position: relative;
    left: 70%;
  }

  .our-people-section .center.icon-left {
    display: none;
  }

  .our-people-section .center.icon-right {
    display: none;
  }
}

.swiper-background {
  // background: url(../images/reports/background/global.png) 0% 0% / cover rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;

  p.research-tile-analyst {
    color: white;
  }

  p.research-subtitle {
    a {
      color: #ffffffcc;
    }
  }
}

.swiper-icon {
  position: relative;
  bottom: 30px;
}

.research-tile-sector {
  position: relative;
  bottom: 10px;
}

.admin-dropdown .dropdown-menu {
  margin: 0 0 0 80px;
}

.blog-dropdown .dropdown-menu {
  margin: 0px;
}
