.btn {
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.btn-danger {
  background-color: #d12433;
  border-color: #d12433;
}

.text-danger {
  color: #d12433 !important;
}