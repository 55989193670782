/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M001 :  09 May 2022 : Adil
    * M-0003826: Cursor hover for Sign Up and Forgot Password should be pointer
**************************************************************************************************************
*/

.box-signup {
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.forget-password {
  text-align: center;

  //=====================M001===========[====================
  a {
    cursor: pointer;
  }
  //=====================M001===========]====================

  @media screen and (min-width: 768px) {
    margin: auto;
    width: 50%;
  }
}

body {
  overflow: auto !important;
}

.form-cookie {
  padding: 10px;
}