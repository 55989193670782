/*
**************************************************************************************************************
											R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author:
	Date:
**************************************************************************************************************
											Usage Notes
==============================================================================================================

**************************************************************************************************************
											Amendments
==============================================================================================================
    M009 : 06 July 2023 : Zeeshan Siddique
    * M-4483: Top right hand links (Powered by Spyder, Account, Sign In/Out, Login and Social Media) pop down when browser is resized
    M008 : 06 July 2023 : Zeeshan Siddique
    * M-4445: Styling changes to Powered By Spyder
    M007 : 13 June 2023 : Zeeshan Siddique
      * M-4367: Add a link to the Spyder marketing website on header that reads "Powered by Spyder CRM"
    M006 : 05 Sep 2022 : Nick Ng
      * M-4112: Most Viewed options for time period - Research Portal changes
    M005 : 15 July 2021 : Aman
    * M-0003480: Center align save report/video stars above dat    
    M004 : 1 Mar 2021 : Nick
      * M-0003292: Periodic free access to reports - Website
    M003 : 14 August 2020 : Nicho
	    * M-0002968: Homepage - Our Research section redesign
    M002 : 31 July 2020 : Nicho
      * M-0002967: Homepage - Global Footer redesign
    M001 : 29 July 2020 : Nicho
		* M-0002943: Homepage - Global header redesign
    
**************************************************************************************************************
*/

img.home-banner {
  width: -webkit-fill-available;
}

.pagination > .active > span,
.pagination > .active > span,
.pagination > .active > span {
  border-radius: 30px;
}

.pagination {
  a {
    border-radius: 30px;
    margin-right: 3px;
  }

  li > span {
    padding: 3px 9px;
  }

  li > a,
  .pagination > li > span {
    padding: 3px 9px;
  }

  li:first-child > span {
    border-radius: 30px;
    margin-right: 3px;
  }

  li:last-child > span {
    border-radius: 30px;
    margin-right: 3px;
  }

  .disabled > span {
    border-radius: 30px;
    margin-right: 3px;
  }

  li:last-child > a,
  .pagination > li:last-child > span {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-radius: 30px;
  }
}

.card-avatar-section {
  width: 15%;
  text-align: center;
  background: #eaeaea85;
  // background: #808080;
  border-radius: 5px 0px 0px 5px;

  .sub-heading {
    color: #1e6ca6;
    // color: white;
  }
}

.card-stock-info {
  display: flex;
  font-size: 11px;

  p {
    margin: 0 0 0px;
  }

  .stock-price {
    color: gray;
    padding-right: 6px;
  }

  .stock-share-up {
    color: green;
    padding-right: 6px;
  }

  .stock-share-down {
    color: red;
    padding-right: 6px;
  }

  .company-code {
    font-weight: 700;
    padding-right: 6px;
  }

  .glyphicon {
    top: 2px;
  }
}

.filter-box-section.box {
  padding: 5px;
  border-radius: 5px;
  display: flex;
}

.filter-box-section {
  .navbar-form {
    margin-left: 15px;
  }
}

span.buy-button.label.label-default {
  background: rgb(84, 130, 53);
  font-size: 10.5px;
  margin-right: 5px;
  padding: 1px 5px;
}

.research-card-image {
  width: 70%;
  height: auto;
  margin: 5% 15%;
}

.research-card-image-container {
  width: 50px;
  height: 50px;
  margin: 2% 20%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.research-card-image-container img {
  width: auto;
  height: 100%;
  margin-left: -7px;
}

.ourpeople-card-image-container {
  width: 140px;
  height: 140px;
  margin: 15px 10px 0 10px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.ourpeople-card-image-container img {
  width: auto;
  height: 100%;
  margin-left: -14px;
}

a.card-read-more {
  padding-right: 15px;
  color: #337ab7;
  padding-bottom: 10;
}

.sub-heading {
  color: #1e6ca6;
  font-weight: 700;
  margin: 0px;
  font-size: 10.5px;
  font-family: 'open_sanssemibold';
}

.aletheia-card {
  padding: 0px;
  margin: 5px 0 5px 0;
}

.card-description {
  font-size: 12px;
  margin-bottom: 10px;
  font-family: 'open_sanssemibold';
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  /* fallback */
  max-height: 32px;
  /* fallback */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -moz-line-clamp: 2;
  /* number of lines to show */
}

.one-liner {
  font-family: 'open_sanssemibold';

  /* autoprefixer: off */
  // -webkit-box-orient: vertical;
  // -moz-box-orient: vertical;
  /* autoprefixer: on */
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // line-height: 16px; /* fallback */
  // max-height: 32px; /* fallback */
  // -webkit-line-clamp: 1; /* number of lines to show */
  // -moz-line-clamp: 1; /* number of lines to show */
  p {
    margin: 15px 0 10px;
  }
}

.card-title {
  color: rgb(3, 108, 166);
  font-size: 12.5px;
  text-decoration: none;
  font-weight: 600;
  font-family: 'open_sanssemibold';
}

.card-author {
  padding-left: 10px;
  padding-top: 5px;
  color: #036ca6;
  font-family: 'open_sanssemibold';
  font-size: 12px !important;
}

.subtitle-box {
  display: flex;

  p {
    margin: 0px 10px 0px 0px;
  }

  p.subtitle-left {
    font-size: 12px;
    color: #00b0f0;
  }

  p.subtitle-right {
    font-size: 10.5px;
    color: #2d715a;
    padding-top: 1px;
  }

  .hashtags {
    font-size: 10.5px;
    color: #036ca6;
    padding-top: 2px;
  }
}

.disc-subheading {
  p {
    margin: 2px 0px 0px 0px;
    font-weight: 700;
    font-size: 12px;
    font-family: 'open_sanssemibold';
  }
}

.sector-title {
  font-style: italic;
  font-size: 10.5px;
  margin: 0px;
  font-family: 'open_sanssemibold';
  color: #85878e;
}

.row-wrapper {
  display: table;
  float: none;
  width: 100%;
}

.custom-col-wrapper {
  display: table-cell;
  float: none;
  vertical-align: top;
}

.search-tags {
  max-width: auto;
  border: 2px solid #4990cc;
  border-radius: 15px;
  color: #4990cc;
  font-weight: bolder;
  padding: 3px 9px;
  margin: 3px;
  text-align: center;

  .glyphicon {
    top: 2px;
    font-weight: 200;
    padding: 0px 0px 0px 5px;
  }
}

.custom-flex-box {
  display: flex;
  justify-content: space-between;
}

.filter-icon {
  background: url(../images/filter-icon-25px.png) no-repeat center;
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff;
}

.react-datepicker__month-container {
  float: center;
  width: 100%;
}

.react-datepicker__day.react-datepicker__day--030.react-datepicker__day--selected.react-datepicker__day--today {
  border-radius: 1.3rem;
}

.react-datepicker {
  width: 100%;
  border: 1px solid #ffffff !important;
}

.popover {
  max-width: max-content;
}

.popover-content {
  .form-control {
    display: block;
    margin: 0px 0px 7px 0px;
    width: 100%;
    height: 47px;
    padding: 6px 16px 8px 15px;
  }

  .btn {
    width: auto;
  }
}

.popover-footer {
  display: flex;
  justify-content: center;
}

.modal-footer {
  display: flex;
  padding: 6px 18px;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
}

.footer-text-area {
  font-size: 12px;
}

// Filter Box Styling

button.filter-icon.btn-default {
  padding: 17px;
  margin: 4px 2px 3px 14px;
}

.filter-box-table {
  padding: 10px;
  font-size: 12px;

  .form-control {
    height: 30px;
    font-size: 12px;
    box-shadow: none;
  }

  p {
    margin: 2px;
  }

  .DateRangePicker__Month {
    width: 238px;
  }

  .DateRangePicker__CalendarSelection--is-pending {
    background-color: rgb(51, 122, 183);
  }

  .DateRangePicker__MonthHeader {
    color: #337ab7;
  }

  .DateRangePicker__CalendarSelection {
    background-color: #337ab7;
    border: 1px solid #337ab7;
  }

  .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #337ab7;
  }
}

.modal-title {
  margin: 2px;
  font-size: 15px;
  font-weight: 600;
}

.filter-box-button {
  display: flex;

  p {
    color: #1e6ca6;
    font-weight: 700;
    // margin: auto 0px;
    position: relative;
    top: 13px;
    font-size: 14px;
    font-family: 'open_sanssemibold';
  }

  .btn-default {
    border-color: #ccc0;
  }
}

.date-section {
  vertical-align: bottom;
  padding-bottom: 12px;
  width: 10%;
  font-size: 9.5px;
  text-align: center;
  padding-right: 5px;
}

.modal-body {
  position: relative;
  padding: 0px;
}

.filter-tags {
  max-width: auto;
  border: 1.5px solid #4990cc;
  border-radius: 15px;
  color: #4990cc;
  font-size: 12px;
  font-weight: bolder;
  padding: 6px 10px;
  margin: 8px;
  text-align: center;
}

.table-header {
  background: rgb(30, 108, 166);
  font-size: 15px;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.table-bordered {
  font-size: 13px;
}

.forgot-password-modal {
  p {
    margin: 10px;
  }

  form {
    margin: 10px;
  }

  .btn-default {
    color: #ffffff;
    background: #2e6da4;
  }

  .form-control {
    padding: 5px;
  }
}

.forgot-password-button {
  p {
    color: #136ca7;
    cursor: pointer;
  }

  p:hover,
  p:focus {
    text-decoration: underline;
  }
}

.alethiea-footer-section {
  //====================M002==========================
  background: $aletheiaBlue;
  display: flex;
  justify-content: space-between;
}

//====================M001==========================
.alethiea-social-section {
  float: right;
  display: flex;
  justify-content: space-between;
}

//====================M002==========================
.alethiea-footer-flex {
  background: $aletheiaBlue;
  color: white;
  display: flex;
  flex: 1 1;
  margin: 25px -60px 0 0%;
  justify-content: center;

  a,
  a:active,
  a:visited {
    color: white;
    font-weight: 500;
  }

  a:hover {
    color: white;
    text-decoration: none;
  }
}

.alethiea-footer-flex.places {
  // margin: 20px 25% 0px 25%;
  align: center;

  @media (min-width: 1920px) {
    margin: 20px 30% 0px 30%;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    margin: 20px 18% 0px 18%;
  }
}

.footer-divider {
  display: inline-block;
  width: 1px;
  background-color: white;
  margin: -3px 2% 0px 2%;
  height: 2em;
  float: left;
}

.footer-dot {
  position: relative;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin: 8px 5px 0px 5px;
}

.text-center.row.footer-bar {
  margin: 0 3%;
}

//====================M002==========================

// TAGS Bar Styling
.input-box {
  width: 100%;

  .search_input {
    input {
      width: 100% !important;
      height: 35px;
      margin: 6px;
    }
  }
}

.react-tagsinput {
  background-color: #fff;
  border: none !important;
  width: 100%;
  border-radius: 4px;
  margin-left: 31px;
  padding-top: 0px;
  height: auto;
  cursor: text;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background: transparent;

  max-width: 90%;
  border: 1.5px solid #4990cc;
  border-radius: 15px;
  color: #4990cc;
  font-size: 12px;
  font-weight: bolder;
  padding: 0px 12px 5px 10px;
  margin: 5px;
  text-align: center;
  display: inline-block;
  word-break: break-all;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  top: 1.5px;
  left: 3px;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  padding: 5px;
  // width: 80px;
  width: auto;
  margin: 10px;
}

// TAGS Bar Styling END

// Suggestions List Box CSS Started
.aletheia-auto-suggesion:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.aletheia-auto-suggesion {
  text-transform: inherit;
  width: 81.5%;
  /* min-height: 608px; */
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 #494949;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  margin-top: 6px;
  margin-left: 4px;

  h4 {
    height: 30px;
    background-color: #f1f1f1;
    font-size: 12px;
    font-weight: 600;
    /* line-height: 1.29; */
    color: #036ca6;
    padding: 8px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  p {
    text-align: center;
    padding-top: 12px;

    a {
      text-decoration: none;
      height: 42px;
      font-size: 1.5px;
      font-weight: 600;
      line-height: 1.38;
      color: #036ca6;
    }
  }

  .aletheia-auto-result {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.38;

    li {
      cursor: pointer;
      box-shadow: inset 0 -1px 0 0 #e8e8e8;
      list-style-type: none;
      padding: 2px 10px 5px 10px;
      border-top: 1px solid #fafafa;

      a {
        padding: 2px;
        color: #8c8f91;
        display: block;
        text-decoration: none;
      }
    }

    li:hover {
      background-color: #f5f6f7;
    }
  }

  .aletheia-auto-final {
    cursor: pointer;
    text-decoration: none;
    box-shadow: inset 0 -1px 0 0 #e8e8e8;
    padding-bottom: 10px;
  }
}

.aletheia-auto-suggesion-noresult {
  font-size: 1.3rem;
  font-family: 'open_sanssemibold';
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 #494949;
  width: 100%;
  padding: 7px;
  color: red;
}

span.type-of-suggestion.pull-right {
  position: relative;
  bottom: 15px;
  color: #c3c5c9;
  font-size: 10px;
  font-style: italic;
}

// Suggestions List Box CSS Ended
//====================M002==========================
@media screen and (max-width: 768px) {
  .footer-divider {
    display: none;
  }

  .footer-dot {
    display: none;
  }

  .alethiea-footer-flex {
    margin: 3% 0px 3% 0px;
  }

  .alethiea-footer-flex.places {
    display: none;
  }

  //====================M002==========================
  .related-reports-section {
    display: none;
  }

  //====================M003==========================
  .homepage-slider h1 {
    font-size: 13px !important;
  }

  .navbar-header {
    margin-bottom: 2px;
    // padding-top: 4px;
  }

  .forgot-password-header {
    width: 84px;
    font-size: 9px;
    float: right;
    position: relative;
    right: 20%;
    height: 14px;
    top: 1px;
  }

  .header-login-form p.text-danger {
    top: 40px;
    color: white !important;
    padding: 1px 63px 0 5px;
    right: auto;
    z-index: 1;
    background-color: #116da4;
  }

  .our-mission-section {
    .mission-block p {
      font-family: 'open_sansregular';
      line-height: 20px !important;
    }
  }

  .research-card-image-container {
    width: 40px;
    height: 40px;
    /* margin: 2% 25%; */
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  .ourpeople-card-image-container {
    width: 40px;
    height: 40px;
    margin: 2% 20%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  .sector-title {
    // width: 81px;
  }

  .card-stock-info {
    font-size: 10px;
  }

  .card-stock-info .glyphicon {
    display: none;
  }

  // Footer Content Responsivnes
  .alethiea-footer-section {
    flex-direction: column;
  }

  .alethiea-footer-flex {
    flex-direction: column;
  }

  .aletheia-auto-suggesion {
    right: 5%;
  }

  .homepage-slider {
    // height: 201px !important;
    // .header-grey-strip {
    //   height: 10px;
    // }

    // }
    h1 {
      padding-top: 50px;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 768px) {
  .pdf-viewer-section .section-header .buttons-box {
    left: 16px;
  }
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

// Settings Page CSS
.box-signup .box-settings-logo {
  height: 81px;
  margin-left: 30%;
  margin-top: 25%;
}

.popover-content {
  padding: 2px 14px;
  font-size: 10px;
}

// @media screen and (max-width: 1224px) {
//   .related-reports-section {
//     display: none;
//   }
// }

button.pull-right.copy.btn.btn-xs.btn-primary {
  padding: 2px 5px;
  font-size: 12.2px;
  line-height: 1.5;
  border-radius: 14px;
  position: relative;

  .glyphicon {
    left: 0.5px;
  }
}

.invisible-div {
  height: 10px;
  z-index: 10;
  position: absolute;
  width: 100%;
  opacity: 0.6;
}

header.header-stickky {
  nav.header-blue.navbar.navbar-default.navbar-fixed-top {
    top: -71px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -ms-transform: all 0.5s ease 0s;
  }
}

header.show-header {
  nav.header-blue.navbar.navbar-default.navbar-fixed-top {
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -ms-transform: all 0.5s ease 0s;
    top: 0;
  }
}

.animated.fadeInDown {
  display: contents;
}

// .pdf-col.col-lg-12.col-md-12 {
//   z-index: -1;
// }

.open-report-button-section {
  position: relative;
  top: 16%;

  button.btn.btn-primary.open-report {
    height: 23px;
    font-size: 10px;
    padding: 3px 12px;
  }
}

.open-report-button-section-external-pdf {
  position: relative;
  top: 5%;

  button.btn.btn-primary.open-report {
    height: 23px;
    font-size: 10px;
    padding: 3px 12px;
  }
}

.hashtags {
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  font-family: 'open_sanssemibold';
  color: #00b0f0;
}

.hashtags:hover {
  cursor: pointer;
  text-decoration: underline;
}

//M005
.SaveReport {
  margin-bottom: 10px;
}

.SaveReport:hover {
  cursor: pointer;
}

//M005
.report-viewer-star {
  height: 80%;
  position: relative;
  top: 5px;
  right: 20px;
}

.report-viewer-star:hover {
  cursor: pointer;
}

.report-viewer-highlight {
  width: 25px;
  position: relative;
  top: 8px;
  right: 5px;
}

.report-viewer-highlight:hover {
  cursor: pointer;
}

//=============M004=============]=========
.notify-box-msg {
  a {
    color: white !important;
    text-decoration: underline;
  }

  a :hover {
    color: white !important;
    text-decoration: underline;
  }
}

//=============M004=============]=========
.react-pdf__Page__textContent {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

@media screen and (max-width: 1200px) {
  .pdf-viewer-admin-social {
    display: none !important;
  }

  .pdf-viewer-admin {
    display: block !important;
  }
}

@media screen and (min-width: 1200px) {
  .pdf-viewer-admin-social {
    display: block !important;
  }

  .pdf-viewer-admin {
    display: none !important;
  }
}

.social-media-modal {
  width: 1200px !important;
}

.konvajs-content {
  margin: auto;
}

@media screen and (min-width: 768px) {
  .type-tab-bar-div {
    padding: 5px 0 0 0;
    justify-content: center;
    font-size: 115%;
    font-weight: 700;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: block !important;
  }

  .type-tab-bar-div .type-tab-normal {
    text-align: center;
    padding: 5px;
    background-color: #b8e6fe;
    color: #036ca6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
  }

  .type-tab-bar-div .type-tab-highlighted {
    text-align: center;
    padding: 5px;
    background-color: #036cae;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .type-tab-bar-div {
    display: none !important;
  }
}

//===============M006======[===========
.most-viewed-dropdown {
  a {
    color: white;
  }

  a,
  a:hover,
  a:focus {
    cursor: pointer;
    color: white;
  }

  .dropdown-menu {
    margin-top: 0;
    right: -10px;
  }
}

//===============M006======]===========

//===============M007======[===========
.power-by-header {
  align-items: center;
  height: 47.97px;
  float: left;
  display: flex;
  margin-right: 20px;
  margin-top: 3px;
  font-size: 12px;
  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  a:focus {
    color: white;
    text-decoration: none;
  }
  a:visited {
    color: white;
    text-decoration: none;
  }
}
@media screen and (max-width: 768px) {
  .power-by-header {
    display: none;
  }
}
//===============M009======]===========

@media screen and (max-width: 1200px) {
  .power-by-header {
    display: none;
  }
}
//===============M009======]===========

@media screen and (max-width: 768px) {
  .power-by-footer {
    display: none;
  }
}
.power-by-footer {
  // top: -22px;
  margin: 25px 0px 25px 0px;
  font-size: 12px;
  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  a:focus {
    color: white;
    text-decoration: none;
  }
  a:visited {
    color: white;
    text-decoration: none;
  }
}

//===============M007======[===========

//===============M008======[===========

.power-by-header-before-login {
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  float: left;
  display: flex;
  margin-right: 10px;
  font-size: 12px;
  align-content: center;
  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  a:focus {
    color: white;
    text-decoration: none;
  }
  a:visited {
    color: white;
    text-decoration: none;
  }
}
.search-box.navbar-form.navbar-left {
  width: auto !important ;
  right: 22% !important;
  display: flex;
}
//===============M008======[===========
