.our-team-box.box {
  .team-members-section {
    display: flex;
    justify-content: center;
    padding-top: 10;
  }
}

@media screen and (max-width: 768px) {
  .our-team-box.box {
    .team-members-section {
      display: block;
    }
  }
}
