/*
**************************************************************************************************************
										R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author:
	Date:
**************************************************************************************************************
											Usage Notes
==============================================================================================================

**************************************************************************************************************
											Amendments
==============================================================================================================
	  M002 : 21 Sep 2022 : Nick Ng
		   * M-3844: Allow video access without subscription checking
    M001 : 27 Apr 2021 : Nick
       * M-3382: Website UI Improve - Aletheia Connect  
**************************************************************************************************************
*/

body {
  background: white;
}
.headerContainer {
  width: 100%; //M001
  background-color: #2d4471;
  color: white;
  margin-top: -1%;
  font-family: open_sansregular, sans-serif;
  font-weight: 500;
}
.headerTitle {
  font-size: 48px;
}
.headersection1 {
  //padding-left: 15%;
  text-align: center;
  margin-top: 1%; //M001
  margin-bottom: 1%; //M001
}

.amp-logo > div {
  height: 125px !important;
  background-image: url(https://www.aletheia-capital.com/static/media/aletheia-white-75x75-2.34fcc469.svg) !important;
}

.amp-content-title {
  margin-top: calc(0.01em) !important;
}

.headerImagediv {
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 80px;
  width: 100%;
  background-image: url(../images/login-logo-small.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150px 150px;
  position: absolute;
  z-index: 3;
  opacity: 0.4;
}
.headersection2 {
  text-align: center; //M001
  margin-bottom: 1%; //M001
  font-size: 17px;
}

.VideoTitleSection {
  font-family: open_sansregular, sans-serif;
  font-size: 15px;
  color: #036ca6;
}
.VideoPlayerSection {
  width: 100%;
  border: 3px solid #036ca6;
  background-image: url(../images/play-line-thin-blue.svg);
  background-size: 200px 200px;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 499px;
  min-height: 200px; //M002
}
.VideoMoreInfo {
  text-align: right; //M001
}
.VideoMoreInfo:hover {
  cursor: pointer;
}
.ListTitle {
  font-size: 25px;
  color: #2d4471;
  font-weight: bold;
  font-family: open_sansregular, sans-serif;
  border-bottom: 1px solid #7e8387;
}
.VideoListCategory {
  text-transform: uppercase;
  color: #2d4471;
  font-family: open_sansregular, sans-serif;
  font-size: 12px;
  font-weight: bold;
}
.VideoListType {
  display: inline-block;
  margin-bottom: 0px;
  color: #979899;
  text-transform: capitalize;
}
.VideoListType li {
  font-size: 11px;
  color: black;
}
.VideoListTitle {
  font-size: 17px;
  font-family: open_sansregular, sans-serif;
  color: #125183;
  font-weight: bold;
}
.VideoListAuthor {
  font-weight: bold;
}
.AuthorInfospan {
  font-size: 12px;
  color: #3a3a3a;
}
.VideoInfoDiv {
  border-bottom: 1px solid #036ca6;
  padding-bottom: 4%;
}
.VideoDescription {
  font-size: 12px;
  margin-top: 10px;
  line-height: 18px;
  max-height: 54px;
  overflow: hidden;
  color: #3a3a3a;
}
.ReadmoreSpan {
  font-size: 12px;
  color: #2d4471;
}

.LatestListPlayDiv {
  border: 2px solid #036ca6;
  height: 40px;
  width: 40px;
  background-image: url(../images/play-filled-thin-blue.svg); //M001
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-size: 33px;
}
.latestVideoItemDiv {
  border-bottom: 1px solid #7e8387;
  padding-bottom: 5%;
  padding-top: 1%;
}
.latestVideoItemDiv:hover {
  cursor: pointer;
}
.latestVideoItemDiv:hover .VideoDescription {
  color: #036ca6;
}
// M001
.LatestListPlayDiv:hover {
  background-image: url(../images/play-filled-thin-blue.svg);
}
.MostWatchedListDiv {
  border-top: 1px solid #7e8387;
  border-left: 1px solid #7e8387;
  border-right: 1px solid #7e8387;
}
.MostWatchedDate {
  color: black;
  font-family: open_sansregular, sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-left: 15%;
}
.MostandSavedListPlayDiv {
  border: 2px solid #036ca6;
  height: 40px;
  width: 40px;
  margin-top: 20%;
  background-image: url(../images/play-line-thin-blue.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-size: 33px;
}
.MostandSavedVideoItemDiv {
  padding-bottom: 5%;
  padding-top: 1%;
}
.MostandSavedVideoItemDiv:hover {
  cursor: pointer;
}
.MostandSavedVideoItemDiv:hover .MostandSavedListPlayDiv {
  background-image: url(../images/play-filled-thin-blue.svg);
}
.MostWatchedItemDiv {
  border-bottom: 1px solid #7e8387;
  padding-top: 1%;
  padding-bottom: 1%;
}
.SavedTitleDiv {
  padding-top: 5%;
}
.amp-default-skin .vjs-big-play-button {
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.most-watch {
  min-height: 400px;
}

.saved-watch {
  min-height: 400px;
}

.all-videos {
  min-height: 800px;
}
.mp-component_azure-mp-container__idgeq {
  background: none !important;
}

.mp-component_azure-mp-container__idgeq > div {
  max-height: 493px;
}

.star-save {
  width: 25px;
  height: 20px;
  float: right;
}

@media (max-width: 850px) {
  .VideoListType li {
    font-size: 7px;
  }
  .VideoListCategory {
    font-size: 8px;
  }
  .MostandSavedListPlayDiv {
    height: 40px;
  }
  .headersection2 {
    text-align: center;
    margin-top: 0%;
  }
  .headerImagediv {
    width: 100%;
  }
}

.connect-table .table-header {
  width: auto !important;
}
.connect-table td {
  width: 100px !important;
}

@media (min-width: 768px) {
  .connect-confirm-modal .modal-dialog {
    min-width: 768px !important;
    max-width: 768px !important;
  }
  .connect-invitation-image {
    margin: 0 50px;
  }
}

@media (max-width: 768px) {
  .connect-confirm-modal .table-header {
    width: auto !important;
  }
  .connect-confirm-modal .table_tr_0 {
    display: none;
  }
  .connect-confirm-modal .table_tr_5 {
    display: none;
  }
  .connect-confirm-modal table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }
  .connect-table table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }
}

.connect-table {
  margin: 10px;
}

.connect-table td {
  border: 0px !important;
}

.connect-invitation-image {
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .connect-invitation-image-mobile {
    width: 100% !important;
  }
}

.custom-input-connect-banner {
  width: 200px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: #206da4;
}

.custom-input-connect-banner::after {
  content: 'Replace the banner';
}
.custom-input-connect-banner input {
  opacity: 0;
  width: 1px;
  height: 4px;
  overflow: hidden;
}

.highlighted-transcript {
  border: 1px solid #2d4471;
  background: rgba(45, 68, 113, 0.9);
  color: white;
  border-radius: 4px;
  padding: 7px;
}

button.trasncript-save-btn {
  float: right;
  margin: 0px;
  position: absolute;
  right: 20px;
  top: 5px;
  color: #206da4;
}
.transcript-edit-icon {
  cursor: pointer;
  color: #206da4;
  top: 5px;
  right: 10px;
}
.bookingCard-container {
  border: 1px solid #1e6ca6;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.bookingCard-button {
  font-size: 14px !important;
  text-transform: capitalize !important;
}
.bookingCard-cancel-button {
  font-size: 14px !important;
  text-transform: capitalize !important;
  background-color: #d3202e !important;
  border-color: #d3202e !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
}
